import CustomPage from 'components/CustomPage';
import React from 'react';
import Beneficiarios from './components/Beneficiarios';
import Coopagos from './components/Coopagos';
import Download from './components/Download';
import Formulario from './components/Formulario';
import SectionHome1 from './components/HeaderPage';
import InstitucionesAliadas from './components/InstitucionesAliadas';
import MisionVision from './components/MisionVision';
import Servicios from './components/Servicios';
import Servicios2 from './components/Servicios2';
import StepInstall from './components/StepInstall';

class Home_Coopagos extends React.Component {
  render() {
    return (
      <CustomPage>
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT  */}
        <div id="page" className="page">
          <div id="hero-gradient" className="shapes-container" >
            <div className="bg-shape" />
          </div>
          <br />
          <SectionHome1 />
          <br />
          <MisionVision /> 
          <Servicios2 /> 
          <br /> 
          <br />
          <br />
          <StepInstall />
          <br />
          <Coopagos />
          <br />
          <Download />
          <Formulario />
          <InstitucionesAliadas />
        </div>	{/* END PAGE CONTENT */}

      </CustomPage>
    )
  }
}
export default Home_Coopagos 