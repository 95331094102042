import React, {Component} from 'react';

class Reviews extends React.Component {
    render() {
        return(
            <div>



        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
         
          {/* TESTIMONIALS-4
			============================================= */}
          <section id="reviews-4" className="bg_whitesmoke  hero-offset-nav pb-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Why People Use NordEx®</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Etiam sapien sem at sagittis congue an augue massa varius an egestas suscipit magna a tempus aliquet 
                            blandit <span className="txt-highlight">tempor sapien gravida donec</span> ipsum porta	 			   
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">John Sweet</h5>
                          <p>Mac Store Review, USA</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>An augue cubilia laoreet undo magna a suscipit egestas magna ipsum at ligula vitae 
                            <span className="txt-highlight">purus ipsum primis</span> cubilia
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Mark Hodges</h5>
                          <p>Marketing Manager, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Etiam sapien sem at sagittis congue an <span className="txt-highlight">augue massa and varius </span>
                            undo egestas suscipit magna a tempus aliquet blandit tempor sapien gravida donec ipsum 	 
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">John W. Stucky</h5>
                          <p>Mac Store Review, UK</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                      {/* TESTIMONIAL #4 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>An augue cubilia laoreet undo magna a suscipit <span className="txt-highlight">egestas magna ipsum</span> 
                            at ligula vitae purus ipsum primis cubilia
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Justin T. Roberto</h5>
                          <p>Marketing Manager, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #4 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #5 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula augue lectus 
                            aenean  ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo 
                            tortor tempor sapien gravida donec ipsum porta
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Dominic Johnson</h5>
                          <p>Chief Strategist, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #5 */}
                      {/* TESTIMONIAL #6 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>An augue cubilia laoreet undo <span className="txt-highlight">magna a suscipit egestas magna ipsum</span>
                            at ligula vitae purus ipsum primis cubilia
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Becky Sterling</h5>
                          <p>Interior Designer</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #6 */}
                      {/* TESTIMONIAL #7 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula augue lectus 
                            aenean  ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo 
                            tortor tempor sapien gravida donec ipsum porta
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Kally Brooks</h5>
                          <p>Web Developer</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #7 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
            </div>     {/* End container */}
            {/* GEOMETRIC OVERLAY */}	
            <div className="bg_fixed geometric_overlay" />
          </section>	{/* END TESTIMONIALS-4 */}
          {/* PAGE PAGINATION
			============================================= */}
          <div className="bg_whitesmoke pb-100 page-pagination division">
            <div className="container">
              <div className="row">	
                <div className="col-md-12">
                  <nav aria-label="Page navigation">
                    <ul className="pagination ico-20 justify-content-center">
                      <li className="page-item disabled"><a className="page-link" href="#" tabIndex={-1}>
                          <span className="flaticon-chevron-pointing-to-the-left" />
                        </a></li>
                      <li className="page-item active"><a className="page-link" href="#">1 <span className="sr-only">(current)</span></a></li>
                      <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item"><a className="page-link" href="#">4</a></li>
                      <li className="page-item"><a className="page-link" href="#"><span className="flaticon-right-chevron" /></a></li>
                    </ul>	
                  </nav>					
                </div>
              </div>  {/* End row */}	
            </div> {/* End container */}
          </div>	{/* END PAGE PAGINATION */}
          {/* DOWNLOAD-2
			============================================= */}
          <section id="download-2" className="bg_whitesmoke pb-20 download-section division">
            <div className="container white-color">
              <div className="rel purple_gradient bg_shape_01 downloads-2-wrapper">
                <div className="row d-flex align-items-center">
                  {/* DOWNLOAD TEXT */}
                  <div className="col-lg-7 col-lg-7">
                    <div className="download-2-txt white-color">
                      {/* Icon */}
                      <div className="download-2-logo">
                        <img className="img-fluid" src="assets/images/logo/app-logo.png" alt="app-logo" />
                      </div>
                      {/* Title */}
                      <div className="d2-txt">
                        <h4 className="h4-md">Download NordEx to create your beautiful landing page</h4>
                      </div>
                    </div>
                  </div>
                  {/* STORE BADGES */}
                  <div className="col-lg-5 text-right">
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/tiendas/appstore-white.png" alt="appstore-logo" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/tiendas/googleplay-white.png" alt="googleplay-logo" />
                      </a>
                    </div>	
                  </div>	{/* END STORE BADGES */}
                </div>
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END DOWNLOAD-2 */}
        </div>	{/* END PAGE CONTENT */}
     


            </div>
        )
    }
}
export default Reviews 