import React, { Component } from 'react';

class PlayStore extends React.Component {
  render() {
    return (
      <>
        <a href="#" className="store">
          <div onClick={() => window.open("https://apps.apple.com/ec/app/coopagos/id1535875374")}> 
            <img className="appstore" src="assets/images/tiendas/appstore.png" alt="appstore-badge" />
          </div>
        </a>
      </>
    )
  }
}
export default PlayStore 