import React from 'react';

class LineasCofeps extends React.Component {
    render() {
        return (
            <>
                {/* TESTIMONIALS-2
              ============================================= */}
                <section id="reviews-2" className="reviews-section division  ">
                    <div className="container">
                        <div className="row">
                            {/* TESTIMONIALS TITLE */}
                            <div className="col-lg-4">
                                <div className="reviews-2-title pc-10">
                                    {/* Section ID */}
                                    <span className="section-id grey-color">COFEPS</span>
                                    {/* Title */}
                                    <h2 className="h2-md mb-20">Nuestro equipo, nuestra mejor inversión! </h2>
                                    {/* Text */}
                                    <p className="p-lg">

                                    </p>
                                </div>
                            </div>
                            {/* TESTIMONIALS CAROUSEL */}
                            <div className="col-lg-8">
                                <div className="owl-carousel owl-theme reviews-2-wrapper">
                                    <div className="review radius-08">
                                        <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                                            <img src="assets/images/cofeps/cofeps/1.jpg" alt="content-image" />
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #1 */}
                                    <div className="review radius-08">
                                        <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                                            <img src="assets/images/cofeps/cofeps/3.jpg" alt="content-image" />
                                        </div>
                                    </div>	{/* END TESTIMONIAL #1 */}
                                    {/* TESTIMONIAL #2 */}
                                    <div className="review radius-08">
                                        <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                                            <img src="assets/images/cofeps/cofeps/4.jpg" alt="content-image" />
                                        </div>
                                    </div>	{/* END TESTIMONIAL #2 */}
                                    {/* TESTIMONIAL #3 */}
                                    <div className="review radius-08">
                                        <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                                            <img src="assets/images/cofeps/cofeps/6.jpg" alt="content-image" />
                                        </div>
                                    </div>	{/* END TESTIMONIAL #3 */}
                                    {/* TESTIMONIAL #4 */}
                                    <div className="review radius-08">
                                        <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                                            <img src="assets/images/cofeps/cofeps/8.jpg" alt="content-image" />
                                        </div>
                                    </div>	{/* END TESTIMONIAL #4 */}
                                </div>
                            </div>	{/* END TESTIMONIALS CAROUSEL */}
                        </div>
                    </div>     {/* End container */}
                </section>	{/* END TESTIMONIALS-2 */}
            </>
        )
    }
}
export default LineasCofeps 