import React from 'react';
import Footer from './Footer';
import Header from './Header';

class CustomPage extends React.Component {
	render() {
		const { colorHeader } = this.props;
		return (
			<>
				<Header colorHeader={colorHeader} />
				{this.props.children}
				<Footer />
			</>
		)
	}
}
export default CustomPage
