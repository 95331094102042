import React, { component } from 'react';
import { Link } from "react-router-dom";
import openInNewTab from 'util';
import './a.css'

class Footer extends React.Component {
	render() {
		return (
			<div>
				<footer class="footer-section">
					<div class="container">
						<div class="footer-content pt-50 pb-5">
							<div class="row">
								<div class="col-xl-4 col-lg-4 mb-50">
									<div class="footer-widget">
										<div class="footer-logo">
											<a href="index.html">
												<img src="assets/images/cofeps/logo.png" alt="logo" />
											</a>
										</div>
										<div class="footer-text">
											<p>Corporación de Fomento al Emprendimiento Productivo y Solidario</p>
										</div>
									</div>
								</div>
								<div class="col-xl-4 col-lg-4 col-md-6 mb-50">
									<div class="footer-widget">
										<div class="footer-widget-heading">
											<h3>Contacto</h3>
										</div>
										<div class="footer-text mb-25">
											<p>
												<a href={"tel:" + '+593 (0)992114930'}>
													<span class="flaticon-telephone"></span>
													&nbsp;&nbsp;{'  +593 (0)992114930'}
												</a>
											</p>
											<p>
												<a href={"mailto:" + 'coopagos.app@cofeps.com.ec'}>
													<span class="flaticon-email"></span>
													&nbsp;&nbsp;{'  coopagos.app@cofeps.com.ec'}
												</a>
											</p>
										</div>
										<div class="footer-widget">
											<div class="footer-social-icon ">
												<div class="text-center" style={{ display: 'flex' }}> 
													<a target="_blank"  href={"https://api.whatsapp.com/send?phone=593992114930"}><span class="flaticon-whatsapp"></span></a>
													<a target="_blank"  href="https://www.facebook.com/cofeps/"><span class="flaticon-facebook"></span></a>
													<a target="_blank"  href="https://instagram.com/cofeps.ec?igshid=YmMyMTA2M2Y="><span class="flaticon-instagram"></span></a>
													<a target="_blank"  href="https://ec.linkedin.com/in/corporaci%C3%B3n-cofeps-0267ab253"><span class="flaticon-linkedin-sign"></span></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-4 col-lg-4 col-md-6 mb-50">
									<div class="footer-widget">
										<div class=" footer-widget-heading">
											<h3>Dirección</h3>
										</div>
										<div class="footer-text mb-25">
											<p>
												<a target="_blank" href="https://goo.gl/maps/L8NqofM9CK1VsNan8">
													<span class="flaticon-map-marker"></span>
													&nbsp;&nbsp;De los Huancavilcas y Colorados 10-56.<br /> Totoracocha <br />
													Cuenca - Ecuador
												</a>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="copyright-area">
						<div class="container">
							<div class="row">
								<div class="col-xl-12 col-lg-12 text-center ">
									<div class="copyright-text">
										<p>Copyright &copy; 2023. COFEPS. Todos los derechos reservados </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		)
	}
}
export default Footer    