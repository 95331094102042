import React from 'react';

class Servicios2 extends React.Component {
    render() {
        return (
            <>
                <section id="features-13" className="rel aliceblue_shape mt-inverse-40 features-section division">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            {/* TEXT BLOCK */}
                            <br />
                            <div className="col-md-10 col-lg-5">
                                <div className="txt-block pc-15 wow fadeInUp" data-wow-delay="0.4s">
                                    {/* Section ID */}
                                    <span className="section-id grey-color">COOPAGOS</span>
                                    {/* Title */}
                                    <h2 className="h2-md">¿Qué puedes hacer con Coopagos?</h2>
                                    {/* Text */}
                                    <p className="p-lg"> Contamos con varios servicios que permitiran realizar transacciones de manera facil y segura.
                                    </p>
                                    {/* Text */}
                                    <p className="p-lg">¡Sin costos de transacción y de manera inmediata!</p>
                                </div>
                            </div>	{/* END TEXT BLOCK */}
                            {/* FEATURES-13 WRAPPER */}
                            <div className="col-lg-7">
                                <div className="fbox-13-wrapper pl-35">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* FEATURE BOX #1 */}
                                            <div id="fb-13-1" className="fbox-13 mt-50 mb-30 wow fadeInUp" data-wow-delay="0.4s">
                                                {/* Icon */}
                                                <div className="fbox-13-ico ico-75">
                                                    <img className="img-fluid" src="assets/images/coopagos/iconos/iconos-01.png" alt="content-image" />
                                                </div>
                                                {/* Title */}
                                                <h5 className="h5-sm">Autenticación</h5>
                                                {/* Text */}
                                                <p className="p-lg">Ingreso biometrico o por PIN al aplicativo</p>
                                            </div>
                                            {/* FEATURE BOX #2 */}
                                            <div id="fb-13-2" className="fbox-13 wow fadeInUp" data-wow-delay="0.6s">
                                                {/* Icon */}
                                                <div className="fbox-13-ico ico-75">
                                                    <img className="img-fluid" src="assets/images/coopagos/iconos/iconos-02.png" alt="content-image" />
                                                </div>
                                                {/* Title */}
                                                <h5 className="h5-sm">Pagos y Cobros</h5>
                                                {/* Text */}
                                                <p className="p-lg">Coopagos te permite realizar pagos o cobros a cuentas de la misma institución o entre cuentas de las instituciones aliadas. </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {/* FEATURE BOX #3 */}
                                            <div id="fb-13-3" className="fbox-13 mb-30 wow fadeInUp" data-wow-delay="0.4s">
                                                {/* Icon */}
                                                <div className="fbox-13-ico ico-75">
                                                    <img className="img-fluid" src="assets/images/coopagos/iconos/iconos-03.png" alt="content-image" />
                                                </div>
                                                {/* Title */}
                                                <h5 className="h5-sm">Consultas</h5>
                                                {/* Text */}
                                                <p className="p-lg">También puedes consultar los movimientos y saldo de la institución activa</p>
                                            </div>
                                            {/* FEATURE BOX #4 */}
                                            <div id="fb-13-4" className="fbox-13 wow fadeInUp" data-wow-delay="0.6s">
                                                {/* Icon */}
                                                <div className="fbox-13-ico ico-75">
                                                    <img className="img-fluid" src="assets/images/coopagos/iconos/iconos-04.png" alt="content-image" />
                                                </div>
                                                {/* Title */}
                                                <h5 className="h5-sm">Cuentas de Ahorro</h5>
                                                {/* Text */}
                                                <p className="p-lg">Permite el registro y mantenimiento de cuentas de ahorro para realizar los pagos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>	{/* End row */}
                            </div>	{/* END FEATURES-13 WRAPPER */}
                        </div>	 {/* End row */}
                    </div>	  {/* End container */}
                </section>
            </>
        )
    }
}
export default Servicios2 