import CustomPage from 'components/CustomPage';
import React from 'react';

class Contacts extends React.Component {
  render() {
    return (
      <CustomPage colorHeader>
        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}
        <div id="page" className="page">
          {/* HEADER
			============================================= */}
          {/* CONTACTS-2
			============================================= */}
          <section id="contacts-2" className="bg_whitesmoke hero-offset-nav pb-50 contacts-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">Como podemos ayudarte?</h2>
                    {/* Text */}
                    <p className="p-xl">
                    </p>
                  </div>
                </div>
              </div>
              {/* CONTACT FORM */}
              <div className="row">
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                  <div className="form-holder">
                    <form name="contactform" className="row contact-form">
                      {/* Form Select */}
                      <div id="input-subject" className="col-md-12 input-subject">
                        <p className="p-lg">Desde donde nos contactas? </p>
                        <select id="inlineFormCustomSelect1" name="Subject" className="custom-select subject">
                          <option>Pertenezco a una organización</option>
                          <option>Socio final</option>
                          <option>Otro</option>
                        </select>
                      </div>
                      <div id="input-name" className="col-md-12">
                        <p className="p-lg">Nombres y apellidos: </p>
                        <input type="text" name="name" className="form-control name" placeholder="Sus nombres completos*" />
                      </div>
                      <div id="input-email" className="col-md-12">
                        <p className="p-lg">Correo: </p>
                        <input type="text" name="email" className="form-control email" placeholder="Correo electrónico válido*" />
                      </div>
                      <div id="input-message" className="col-md-12 input-message">
                        <p className="p-lg">Explique a detalle su inquietud o comentario: </p>
                        <textarea className="form-control message" name="message" rows={6} placeholder="Me gustaria saber mas sobre el servicio..." defaultValue={""} />
                      </div>
                      {/* Contact Form Button */}
                      <div className="col-md-12 mt-15 form-btn text-right">
                        <button type="submit" className="btn btn-skyblue tra-skyblue-hover submit">Conectar</button>
                      </div>
                      {/* Contact Form Message */}
                      <div className="col-lg-12 contact-form-msg">
                        <span className="loading" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>	   {/* END CONTACT FORM */}
            </div>	   {/* End container */}
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>	{/* END CONTACTS-2 */}
          {/* SECTION DIVIDER
			============================================= */}
          <div className="divider-wrapper text-center bg_whitesmoke"><div className="section-divider" /></div>
          {/* FOOTER-3
			============================================= */}
        </div>	{/* END PAGE CONTENT */}
      </CustomPage>
    )
  }
}
export default Contacts 