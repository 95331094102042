import React from 'react';

class MisionVision extends React.Component {
    render() {
        return (
            <>
                {/* CONTENT-2
              ============================================= */}
                <section id="content-2" className="wide-50 content-section division">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            {/* IMAGE BLOCK */}
                            <div className="col-md-5 col-lg-6" style={{ width: '80%' }}>
                                <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                                    <img className="img-fluid" src="assets/images/coopagos/intro2.png" alt="content-image" />
                                </div>
                            </div>
                            {/* TEXT BLOCK */}
                            <div className="col-md-7 col-lg-6">
                                <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                    {/* Section ID */}
                                    <span className="section-id grey-color">COOPAGOS</span>
                                    {/* Title */}
                                    <h2 className="h2-md">¿Quienes somos?</h2>
                                    {/* Text */}
                                    <p className="p-lg">
                                        Coopagos te permite realizar transferencias entre cuentas de la misma institución o entre cuentas de las instituciones aliadas.
                                        También puedes consultar los movimientos y saldo de la institución activa.<br />
                                        ¡Sin costos de transacción y de manera inmediata!
                                    </p>
                                </div>
                            </div>	{/* END TEXT BLOCK */}
                        </div>	   {/* End row */}
                    </div>	   {/* End container */}
                </section>	{/* END CONTENT-2 */}
            </>
        )
    }
}
export default MisionVision 