import React, { Component } from 'react';

class GooglePlay extends React.Component {
  render() {
    return (
      <>
        <a href="#" className="store">
          <div onClick={() => window.open("https://play.google.com/store/apps/details?id=ec.fin.gti.cooppagos")}> 
            <img className="googleplay" src="assets/images/tiendas/googleplay.png" alt="googleplay-badge" />
          </div>
        </a>
      </>
    )
  }
}
export default GooglePlay 