import VideoPopup from 'components/VideoPopup';
import React from 'react';
import { Link } from 'react-router-dom';
import { openInNewTab } from 'util';

class InstitucionesAliadas extends React.Component {
    render() {
        return (
            <>
                {/* BRANDS-1
  ============================================= */}
                <div id="brands-1" className="bg_whitesmoke brands-section division mt-100 mb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center pc-25">
                                {/* Text */}
                                <h2 className="h2-md">Instituciones Aliadas</h2>
                                <br />
                                {/* Brands Carousel */}
                                <div className="owl-carousel brands-carousel">
                                    {/* BRAND LOGO IMAGE */}
                                    <div className="brand-logo">
                                        <div onClick={() => window.open("https://www.jardinazuayo.fin.ec")}>
                                            <img className="img-fluid" src="assets/images/inst/jardin.png" alt="brand-logo" />
                                        </div>
                                    </div>
                                    {/* BRAND LOGO IMAGE */}
                                    <div className="brand-logo">
                                        <div onClick={() => window.open("https://crea.fin.ec/")}>
                                            <img className="img-fluid" src="assets/images/inst/crea.png" alt="brand-logo" />
                                        </div>
                                    </div>
                                    {/* BRAND LOGO IMAGE */}
                                    <div className="brand-logo">
                                        <div onClick={() => window.open("https://cajaonline.caja.com.ec/home")}>
                                            <img className="img-fluid" src="assets/images/inst/caja.png" alt="brand-logo" />
                                        </div>
                                    </div>
                                    {/* BRAND LOGO IMAGE */}
                                    <div className="brand-logo">
                                        <div onClick={() => window.open("https://www.ucacsur.coop/")}>
                                            <img className="img-fluid" src="assets/images/inst/ucacsur.png" alt="brand-logo" />
                                        </div>
                                    </div>

                                </div>	{/* End Brands Carousel */}
                            </div>
                        </div>      {/* End row */}
                    </div>	    {/* End container */}
                </div>	{/* END BRANDS-1 */}
            </>
        )
    }
}
export default InstitucionesAliadas 