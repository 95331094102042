import React, {Component} from 'react';

class Home_10 extends React.Component {
    render() {
        return(
            <div>



        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          {/* HEADER
			============================================= */}
         
          {/* HERO-10
			============================================= */}	
          <section id="hero-10" className="bg_fixed hero-section division">
            <div className="container">	
              <div className="row">	
                <div className="col-xl-10 offset-xl-1">
                  <div className="row d-flex align-items-center m-row">
                    {/* HERO TEXT */}
                    <div className="col-md-7 col-lg-6 m-top">
                      <div className="hero-10-txt white-color wow fadeInRight" data-wow-delay="0.6s">
                        {/* App Logo  */}
                        <div className="hero-app-logo"><img className="img-fluid" src="assets/images/logo/app-logo-fit.png" alt="hero-app-logo" /></div>
                        {/* Title  */}
                        <h2 className="h2-lg">Healthy Mind In A Healthy Body</h2>
                        {/* STORE BADGES */}												
                        <div className="stores-badge mb-10">
                          {/* AppStore */}
                          <a href="#" className="store">
                            <img className="appstore" src="assets/images/tiendas/appstore-white.png" alt="appstore-badge" />
                          </a>
                          {/* Google Play */}
                          <a href="#" className="store">
                            <img className="googleplay" src="assets/images/tiendas/googleplay-white.png" alt="googleplay-badge" />
                          </a> 
                          {/* Aamazon Market 
											<a href="#" class="store">
												<img class="amazonl" src="assets/images/tiendas/amazon.png" alt="amazon-badge" />
											</a>  */}
                          {/* Mac AppStore 
											<a href="#" class="store">
												<img class="mac-appstore" src="assets/images/tiendas/macstore.png" alt="macstore-badge" />
											</a> */} 
                          {/* Microsoft Store  
											<a href="#" class="store">
												<img class="microsoft" src="assets/images/tiendas/microsoft.png" alt="microsoft-badge" />
											</a> */}
                        </div>	{/* END STORE BADGES */}
                        {/* OS Prerequisite */}
                        <span className="os-version">Available for iOS 8 and Android Devices From 5.5</span>	
                      </div>
                    </div> 	  {/* END HERO TEXT */}
                    {/* HERO IMAGE */}							
                    <div className="col-md-5 col-lg-6 m-bottom">	
                      <div className="hero-10-img wow fadeInLeft" data-wow-delay="0.4s">														
                        <img className="img-fluid" src="assets/images/hero/hero-10-img.png" alt="hero-image" />											
                      </div>							
                    </div>
                  </div>
                </div>		
              </div>    {/* End row */}
            </div>     {/* End container */}
          </section>	{/* END HERO-10 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-16.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Unlimited Access</span>
                    {/* Title */}	
                    <h2 className="h2-md">Everything you need in one spot</h2>
                    {/* Text */}
                    <p className="p-lg">Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus mauris donec 
                      a ligula risus 
                    </p>
                    {/* Text */}
                    <p className="p-lg">Aliqum mullam blandit and tempor sapien donec lipsum gravida porta. Velna vitae auctor 
                      congue magna impedit ligula risus. Mauris donec ligula an impedit magnis
                    </p>
                    {/* Button */}
                    <a href="#features-5" className="btn btn-tra-grey skyblue-hover">Find Out More</a>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* FEATURES-13
			============================================= */}
          <section id="features-13" className="rel whitesmoke_shape mt-inverse-50 features-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}	
                <div className="col-md-10 col-lg-5">
                  <div className="txt-block pc-15 wow fadeInUp" data-wow-delay="0.4s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Flexible Features</span>
                    {/* Title */}	
                    <h2 className="h2-md">Simple to use, easy to love</h2>
                    {/* Text */}
                    <p className="p-lg">Semper lacus cursus porta, feugiat primis and donec ultrice ligula tempus an auctor ipsum nihil mauris
                      lectus enim ipsum sagittis congue		   
                    </p>
                    {/* Text */}
                    <p className="p-lg">Gravida porta velna vitae auctor congue magna nihil impedit ligula risus mauris donec ligula</p>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
                {/* FEATURES-13 WRAPPER */}
                <div className="col-lg-7">
                  <div className="fbox-13-wrapper pl-35">	
                    <div className="row">
                      <div className="col-md-6">
                        {/* FEATURE BOX #1 */}
                        <div id="fb-13-1" className="fbox-13 mt-50 mb-30 wow fadeInUp" data-wow-delay="0.4s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75 skyblue-color"><span className="flaticon-clock" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Complete Program</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                        {/* FEATURE BOX #2 */}
                        <div id="fb-13-2" className="fbox-13 wow fadeInUp" data-wow-delay="0.6s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75 skyblue-color"><span className="flaticon-exercise" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Logging Simplified</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* FEATURE BOX #3 */}
                        <div id="fb-13-3" className="fbox-13 mb-30 wow fadeInUp" data-wow-delay="0.8s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75 skyblue-color"><span className="flaticon-salad" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Meal Plans</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                        {/* FEATURE BOX #4 */}
                        <div id="fb-13-4" className="fbox-13 wow fadeInUp" data-wow-delay="1s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75 skyblue-color"><span className="flaticon-lose-weight" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Stay Motivated</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                      </div>
                    </div>
                  </div>	{/* End row */}
                </div>	{/* END FEATURES-13 WRAPPER */}
              </div>	 {/* End row */}	
            </div>	  {/* End container */}
          </section>	{/* END FEATURES-13 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="wide-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-18.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Perfect Integration</span>
                      {/* Title */}	
                      <h2 className="h2-md">Work smarter with powerful features</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus and egestas sapien egestas vitae nemo volute
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                </div>	  {/* End row */}	
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* CONTENT BOX #1 */}
                      <div className="cbox mb-30">
                        {/* Icon */}
                        <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-video-player-2" /></div> 
                        {/* Text */}
                        <div className="cbox-txt">
                          <h5 className="h5-sm">HD Instructional Videos</h5>
                          <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                            suscipit quaerat ultrice tellus viverra
                          </p>
                        </div>
                      </div>	
                      {/* CONTENT BOX #2 */}
                      <div className="cbox mb-30">
                        {/* Icon */}
                        <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-percentage" /></div> 
                        {/* Text */}
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Smart Watch Optimized</h5>
                          <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                            suscipit quaerat ultrice tellus viverra
                          </p>
                        </div>
                      </div>	
                      {/* CONTENT BOX #3 */}
                      <div className="cbox">
                        {/* Icon */}
                        <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-smartphone-9" /></div> 
                        {/* Text */}
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Heart Rate Tracking</h5>
                          <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                            suscipit quaerat ultrice tellus viverra
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-17.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}	
          {/* BRANDS-1
			============================================= */}
          <div id="brands-1" className="bg_whitesmoke brands-section division">
            <div className="container">					
              <div className="row">
                <div className="col text-center pc-25">	
                  {/* Text */}
                  <p className="p-xl">You might know NordEx from:</p>
                  {/* Brands Carousel */}
                  <div className="owl-carousel brands-carousel">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-1.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-2.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-3.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-4.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-5.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-6.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-7.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-8.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-9.png" alt="brand-logo" />
                      </a>
                    </div>
                  </div>	{/* End Brands Carousel */}
                </div>
              </div>      {/* End row */}
            </div>	    {/* End container */}
          </div>	{/* END BRANDS-1 */}
          {/* FEATURES-5
			============================================= */}
          <section id="features-5" className="wide-50 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Get Ready to Be Surprised</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-5 WRAPPER */}
              <div className="fbox-5-wrapper pc-30">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-5-1" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-ads" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Friendly Interface</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-5-2" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-switch" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Powerful Options</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-5-3" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-call" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Quick Access</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #4 */}
                  <div id="fb-5-4" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-analytics-3" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Track &amp; Analyze</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #5 */}
                  <div id="fb-5-5" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1.2s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-weight" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Easy To Follow</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #6 */}
                  <div id="fb-5-6" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-conversation" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Ongoing Support</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-5 HOLDER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-5 */}
          {/* PROCESS-2
			============================================= */}	
          <section id="process-2" className="bg_fit_02 wide-100 process-section division">
            <div className="container white-color">	
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                  <div className="section-title text-center mb-60">		
                    <h3 className="h3-sm">Custom workouts on your own time and in your own space</h3>	
                  </div>	
                </div>
              </div>
              <div className="row">
                <ul className="process-skyblue processbar pc-35">
                  {/* PROCESS BOX #1 */}
                  <li id="step-2-1" className="col-md-4">
                    <div className="pbox-2 pc-20 text-center">
                      <h5 className="h5-sm">Get Set Up</h5>							
                      <p className="p-lg">Nemo ipsam egestas volute dolores quaerat sodales</p>		
                    </div>		
                  </li>
                  {/* PROCESS BOX #2 */}
                  <li id="step-2-2" className="col-md-4">
                    <div className="pbox-2 pc-20 text-center">
                      <h5 className="h5-sm">Get Your Plan</h5>											
                      <p className="p-lg">Nemo ipsam egestas volute dolores quaerat sodales</p>
                    </div>		
                  </li>
                  {/* PROCESS BOX #3 */}
                  <li id="step-2-3" className="col-md-4">
                    <div className="pbox-2 pc-20 text-center">
                      <h5 className="h5-sm">Get Moving</h5>		
                      <p className="p-lg">Nemo ipsam egestas volute dolores quaerat sodales</p>
                    </div>		
                  </li>
                </ul>											
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END PROCESS-2 */}
          {/* CONTENT-1
			============================================= */}
          <section id="content-1" className="wide-60 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center m-row">
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-bottom">
                  <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Work Online and Offline</span>
                    {/* Title */}	
                    <h2 className="h2-md">Stay on track and plan your workout</h2>
                    {/* Text */}	
                    <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                      luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                      sodales a sapien
                    </p>
                    {/* Button */}
                    <a href="#faqs-2" className="btn btn-tra-grey skyblue-hover">Read The FAQs</a>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-top">
                  <div className="img-block right-column pc-20 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-19.png" alt="content-image" />
                  </div>
                </div>
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-1 */}
          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="pb-100 content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Connect with Like-Minded People</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/socials.png" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-skyblue tra-grey-hover">
                      See NordEx in Action <span className="flaticon-play-button" />
                    </a> 
                    {/* OS Prerequisite */}
                    <span className="os-version">Available on Android, iOS and macOS</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* TESTIMONIALS-2
			============================================= */}
          <section id="reviews-2" className="reviews-section division">
            <div className="container">
              <div className="row">
                {/* TESTIMONIALS TITLE */}
                <div className="col-lg-4">
                  <div className="reviews-2-title pc-10">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Reviews</span>
                    {/* Title */}
                    <h2 className="h2-md mb-20">Our Happy Customers</h2>
                    {/* Text */}	
                    <p className="p-lg">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and 
                      cursus varius
                    </p>
                  </div>
                </div>
                {/* TESTIMONIALS CAROUSEL */}
                <div className="col-lg-8">					
                  <div className="owl-carousel owl-theme reviews-2-wrapper">
                    {/* TESTIMONIAL #1 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Great Flexibility!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Etiam sapien sem at sagittis congue an augue massa varius egestas undo 
                          suscipit magna tempus undo aliquet				   
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Scott Boxer</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #1 */}
                    {/* TESTIMONIAL #2 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Simple and Useful!</h5>	
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">At sagittis congue augue undo egestas magna ipsum vitae purus and ipsum 
                          primis suscipit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Wendy T.</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #2 */}
                    {/* TESTIMONIAL #3 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">I love this App!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Mauris donec ociis magnis and sapien etiam sapien congue undo augue pretium 
                          and ligula augue a lectus aenean magna
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- pebz13</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #3 */}
                    {/* TESTIMONIAL #4 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-1" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Best App for iOS!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">An augue in cubilia laoreet magna and suscipit egestas magna ipsum purus ipsum 
                          and suscipit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Scott Boxer</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #4 */}
                    {/* TESTIMONIAL #5 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Awesome Design!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Mauris donec magnis sapien undo etiam sapien and congue augue egestas ultrice 
                          a vitae purus velna integer tempor
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- John Sweet</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #5 */}
                    {/* TESTIMONIAL #6 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Simply Amazing App!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">An augue cubilia laoreet undo magna a suscipit undo egestas magna ipsum ligula 
                          vitae purus ipsum primis cubilia blandit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Leslie D.</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #6 */}
                    {/* TESTIMONIAL #7 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Powerful Features!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Augue egestas volutpat and egestas augue in cubilia laoreet magna undo 
                          suscipit luctus
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Marisol19</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #7 */}
                    {/* TESTIMONIAL #8 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Super Support!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis libero tempus 
                          at blandit posuere varius magna
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- AJ</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #8 */}
                  </div>
                </div>	{/* END TESTIMONIALS CAROUSEL */} 
              </div>	
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-2 */}
          {/* PRICING-2
			============================================= */}
          <section id="pricing-2" className="wide-100 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Simple and Flexible Pricing</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row pricing-row">
                {/* FREE PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">NordEx Free</h5>									
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">0</span>
                      <sup className="validity dark-color"><span>.00</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button  */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Download Now</a>
                    </div>	
                  </div>
                </div>	{/* END FREE PLAN */}
                {/* MONTHLY PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">Monthly Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">6</span>
                      <sup className="validity dark-color"><span>.25</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END MONTHLY PLAN  */}
                {/* ANNUAL PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table rel mb-40 wow fadeInUp" data-wow-delay="0.8s">
                    {/* Hightlight Badge */}
                    <div className="badge-wrapper">
                      <div className="highlight-badge bg_skyblue white-color">
                        <h6 className="h6-sm">Save up to 25%</h6>
                      </div>
                    </div>	
                    {/* Plan Price  */}
                    <div className="pricing-plan highlight bg_whitesmoke">
                      <h5 className="h5-xs">Annual Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">5</span>
                      <sup className="validity dark-color"><span>.69</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END ANNUAL PLAN */}
              </div>	{/* END PRICING TABLES */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                    {/* Button */}
                    <a href="pricing.html" className="btn btn-md btn-tra-grey skyblue-hover mb-10">Start Free 14-day Trial</a>
                    {/* OS Prerequisite */}
                    <span className="os-version">Request OS X 10.10 or later</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="bg_dark wide-60 content-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}		
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Totally Optimized</span>
                    {/* Title */}	
                    <h2 className="h2-md">Intuitive features, powerful results</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                  </div>	
                </div>	{/* END TEXT BLOCK */}		
                {/* IMAGE BLOCK */}	
                <div className="col-md-6 col-xl-6">
                  <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/tablet.png" alt="content-image" />
                  </div>	
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* CONTENT-14
			============================================= */}
          <section id="content-14" className="whitesmoke_shape content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-40">		
                    {/* Title */}	
                    <h2 className="h2-md">Accessible for All Platforms</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/browsers.png" alt="content-image" />
                  </div>
                </div>	
              </div>	{/* END TEXT BLOCK */}	
            </div>     {/* End container */}
          </section>	{/* END CONTENT-14 */}
          {/* FAQs-2
			============================================= */}
          <section id="faqs-2" className="wide-100 faqs-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Got Questions? Look Here</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FAQs-2 QUESTIONS */}	
              <div className="faqs-2-questions pc-15">
                <div className="row">
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #1 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I see NordEx in action before purchasing it?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit in odio integer congue metus vitae arcu mollis blandit 
                          ultrice ligula egestas and magna suscipit lectus magna suscipit luctus blandit vitae
                        </p>
                      </div>	
                      {/* QUESTION #2 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">What are the requirements for using NordEx?</h5>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec ipsum enim an porta justo integer at velna 
                          vitae auctor integer congue undo magna at pretium purus pretium ligula 
                        </p>
                      </div>
                      {/* QUESTION #3 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.8s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I use NordEx on different devices?</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus ligula euismod pretium purus
                              pretium rutrum tempor sapien
                            </p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                              pretium a purus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #4 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Do you have a free trial?</h5>
                        {/* Answer */}
                        <p className="p-lg">Cubilia laoreet augue egestas and luctus donec curabite diam vitae dapibus libero and 
                          quisque gravida donec neque. Blandit justo aliquam molestie nunc sapien justo
                        </p>
                      </div>
                      {/* QUESTION #5 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">How does NordEx handle my privacy?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit sit amet in odio. Integer congue leo metus. Vitae arcu mollis 
                          blandit ultrice ligula
                        </p>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec congue leo metus. Vitae arcu mollis blandit 
                          integer at velna
                        </p>
                      </div>
                      {/* QUESTION #6 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.8s">
                        {/* Question */}
                        <h5 className="h5-sm">I have an issue with my account</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus</p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Quaerat sodales sapien euismod blandit purus and ipsum primis in cubilia laoreet 
                              augue luctus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                </div>	{/* End row */}
              </div>	{/* END FAQs-2 QUESTIONS */}	
              {/* MORE QUESTIONS BUTTON */}	
              <div className="row">
                <div className="col-md-12">	
                  <div className="more-questions text-center mt-40">
                    <h5 className="h5-sm"><span className="flaticon-check" /> 
                      Have more questions? <a href="mailto:yourdomain@mail.com" className="skyblue-color">Ask your question here</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>	   {/* End container */}		
          </section>	{/* END FAQs-2 */}
          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}	
                  <div className="col-lg-6">
                    <div className="newsletter-txt">	
                      {/* Section ID */}	
                      <span className="section-id">Subscribe to Our Newsletter</span>
                      {/* Title */}	
                      <h4 className="h4-xl">Stay up to date with our news, ideas and updates</h4>	
                    </div>								
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">
                        <input type="email" autoComplete="off" className="form-control" placeholder="Your email address" required id="s-email" />								
                        <span className="input-group-btn">
                          <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Subscribe Now</button>
                        </span>										
                      </div>
                      {/* Newsletter Form Notification */}	
                      <label htmlFor="s-email" className="form-notification" />
                    </form>							
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}	
          </section>	{/* END NEWSLETTER-1 */}
          {/* BLOG-1
			============================================= */}
          <section id="blog-1" className="wide-60 blog-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Our Recipes &amp; Inspiration</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* BLOG POSTS */}
              <div className="row">
                {/* BLOG POST #1 */}
                <div id="b-post-1" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-8-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">NordEx News</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien donec gravida ipsum 300 Calories justo</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>12 min read</p>
                      </div>	
                    </div>
                  </div>
                </div>	{/* END  BLOG POST #1 */}
                {/* BLOG POST #2 */}
                <div id="b-post-2" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-9-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Inspiration</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and dolor libero risus</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>8 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #2 */}
                {/* BLOG POST #3 */}
                <div id="b-post-3" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-10-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Tutorials</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien NordEx, donec 950 Calories an ipsum porta</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>22 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #3 */}
              </div>	{/* END BLOG POSTS */}
            </div>	   {/* End container */}		
          </section>	{/* END BLOG-1 */}
          {/* DOWNLOAD-1
			============================================= */}
          <section id="download-1" className="bg_fit_01 pt-100 download-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center m-row">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-bottom">
                  <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-15-fit.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-top">
                  <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Title */}
                    <h2 className="h2-md">Take your fitness to the next level</h2>
                    {/* Text */}
                    <p className="p-xl">Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus</p>
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/tiendas/appstore-white.png" alt="appstore-logo" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/tiendas/googleplay-white.png" alt="googleplay-logo" />
                      </a>
                    </div>	
                    {/* Rating */}
                    <div className="txt-block-rating">
                      <div className="stars-rating">
                        All Versions Rating
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />	
                        <p className="txt-rating">Based on 7.296 user reviews (App Store &amp; Google Play)</p>
                      </div>			
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END DOWNLOAD-1 */}
          
        </div>	{/* END PAGE CONTENT */}
      

        
            </div>
        )
    }
}
export default Home_10 