import React, {Component} from 'react';

class Home_6 extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          
          
          {/* HERO-6
			============================================= */}	
          <section id="hero-6" className="hero-section division">
            <div className="container">	
              <div className="row">
                {/* HERO TEXT */}
                <div className="col-lg-7">
                  <div className="hero-6-txt pc-25 white-color">
                    {/* Title */}
                    <h2>Connect With Friends</h2>
                    {/* Text */}
                    <p className="p-xl">Feugiat primis ligula risus auctor laoreet and pretium augue egestas mauris in cubilia rutrum justo</p>
                    {/* Buttons Group */}
                    <div className="btns-group mb-25">
                      <a href="#" className="btn btn-md btn-avocado tra-white-hover mr-15">Start For Free</a>
                      <a href="https://www.youtube.com/embed/7e90gBu4pas" className="video-popup2 btn btn-md btn-tra-white white-hover">
                        Watch In Action
                      </a>
                    </div>
                    {/* Rating */}
                    <div className="txt-block-rating">
                      <div className="stars-rating">
                        Customers Rating
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />	
                      </div>		
                      <span className="txt-rating">Based on 7.296 App Store user reviews</span>
                    </div>
                  </div>
                </div>	
                {/* HERO IMAGE */}
                <div className="col-lg-5">	
                  <div className="hero-6-img">				
                    <img className="img-fluid" src="assets/images/hero/hero-6-img.png" alt="hero-image" />				
                  </div>
                </div>
              </div> 	  {/* End row */}
            </div>	    {/* End container */}	
          </section>	{/* END HERO-6 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="wide-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-07.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Unlimited Access</span>
                      {/* Title */}	
                      <h2 className="h2-md">Spend your time with loved ones</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus and egestas sapien egestas vitae nemo volute
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                </div>	  {/* End row */}	
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Perfect Integration</span>
                      {/* Title */}	
                      <h2 className="h2-md">Work smarter with powerful features</h2>
                      {/* List */}	
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                            ligula rutrum tempor sapien
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                            pretium a purus mauris
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-02.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}	
          {/* FEATURES-5
			============================================= */}
          <section id="features-5" className="pb-50 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Get Ready to Be Surprised</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-5 WRAPPER */}
              <div className="fbox-5-wrapper pc-30">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-5-1" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-ads" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Friendly Interface</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-5-2" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-switch" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Powerful Options</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-5-3" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-pantone" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Customization</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #4 */}
                  <div id="fb-5-4" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-user-1" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Multiple Accounts</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #5 */}
                  <div id="fb-5-5" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-browser" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Online Verification</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #6 */}
                  <div id="fb-5-6" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 skyblue-color"><span className="flaticon-spam" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Spam Protection</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-5 HOLDER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-5 */}
          {/* CONTENT-5
			============================================= */}
          <section id="content-5" className="pb-80 content-section">
            <div className="bg-inner bg_whitesmoke pt-100 division">
              <div className="container">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="content-5-img right-column pc-20 text-center wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-04.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Fastest Messaging</span>
                      {/* Title */}	
                      <h2 className="h2-md">Unlimited calling, texting and picture messaging</h2>
                      {/* Text */}
                      <p className="p-lg">Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus. Mauris donec 
                        ligula and magnis 
                      </p>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                    </div>	
                  </div>	{/* END CONTENT TXT */}	
                </div>	  {/* End row */}
              </div>	   {/* End container */}
            </div>		{/* End Inner Background */}	
          </section>	{/* END CONTENT-5 */}
          {/* CONTENT-1
			============================================= */}
          <section id="content-1" className="wide-60 content-section division">
            <div className="container rel">
              <div className="row d-flex align-items-center m-row">
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-bottom">
                  <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    {/* CONTENT BOX #1 */}
                    <div className="cbox mb-30">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-video-player" /></div> 
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Text, Voice &amp; Video Calls</h5>
                        <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                          suscipit quaerat ultrice tellus viverra
                        </p>
                      </div>
                    </div>	
                    {/* CONTENT BOX #2 */}
                    <div className="cbox mb-30">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-smiley" /></div> 
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Stickers, Emojis, Themes</h5>
                        <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                          suscipit quaerat ultrice tellus viverra
                        </p>
                      </div>
                    </div>	
                    {/* CONTENT BOX #3 */}
                    <div className="cbox">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-image" /></div> 
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Share Files &amp; Media</h5>
                        <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                          suscipit quaerat ultrice tellus viverra
                        </p>
                      </div>
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-top">
                  <div className="img-block right-column pc-20 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-05.png" alt="content-image" />
                  </div>
                </div>
              </div>	{/* End row */}
              {/* Transparent Background Elements */}
              <div className="tra-bkg-wrapper">
                <div className="bg_el_05 wow fadeInRight" data-wow-delay="0.8s" />
              </div>
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-1 */}
          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="pb-100 content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Chat with Your Friends Easily</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/socials.png" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-skyblue tra-grey-hover">
                      See NordEx in Action <span className="flaticon-play-button" />
                    </a> 
                    {/* OS Prerequisite */}
                    <span className="os-version">Available on Android, iOS and macOS</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* TESTIMONIALS-4
			============================================= */}
          <section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Why People Use NordEx®</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Etiam sapien sem at sagittis congue an augue massa varius an egestas suscipit magna a tempus aliquet 
                            blandit <span className="txt-highlight">tempor sapien gravida donec</span> ipsum porta	 			   
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">John Sweet</h5>
                          <p>Mac Store Review, USA</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>An augue cubilia laoreet undo magna a suscipit egestas magna ipsum at ligula vitae 
                            <span className="txt-highlight">purus ipsum primis</span> cubilia
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Mark Hodges</h5>
                          <p>Marketing Manager, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula augue lectus 
                            aenean  ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo 
                            tortor tempor sapien gravida donec ipsum porta
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Dominic Johnson</h5>
                          <p>Chief Strategist, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/tiendas/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/tiendas/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/tiendas/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/tiendas/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/tiendas/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}
                    {/* OS Prerequisite */}
                    <span className="os-version">Available for iOS 8 and Android Devices From 5.5</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-4 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-03.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Beauty of Simplicity</span>
                    {/* Title */}	
                    <h2 className="h2-md">Beautiful, award-winning design</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="pb-60 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}		
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Totally Optimized</span>
                    {/* Title */}	
                    <h2 className="h2-md">Intuitive features, powerful results</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                    {/* Button */}	
                    <a href="#" className="btn btn-tra-white skyblue-hover">Find Out More</a>
                  </div>	
                </div>	{/* END TEXT BLOCK */}		
                {/* IMAGE BLOCK */}	
                <div className="col-md-6 col-xl-6">
                  <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/tablet.png" alt="content-image" />
                  </div>	
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* CONTENT-14
			============================================= */}
          <section id="content-14" className="whitesmoke_shape content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-40">		
                    {/* Title */}	
                    <h2 className="h2-md">Accessible for All Platforms</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/browsers.png" alt="content-image" />
                  </div>
                </div>	
              </div>	{/* END TEXT BLOCK */}	
            </div>     {/* End container */}
          </section>	{/* END CONTENT-14 */}
          {/* BRANDS-2
			============================================= */}
          <section id="brands-2" className="wide-70 brands-section division">
            <div className="container">	
              {/* BRANDS TITLE */}
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="brands-title text-center">
                    <h4 className="h4-sm">You might know us from:</h4>
                  </div>
                </div>
              </div>
              {/* BRANDS-2 WRAPPER */}
              <div className="brands-2-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-1.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-2.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-3.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-4.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-5.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-6.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-7.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-8.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-9.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-10.png" alt="brand-logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>  {/* END BRANDS-2 WRAPPER */}
            </div>     {/* End container */}
          </section>	{/* END BRANDS-2 */}
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_selfy wide-100 download-section division">
            <div className="container white-color">
              <div className="row">	
                {/* DOWNLOAD TXT */}	
                <div className="col-lg-6">	
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Voice &amp; Video Calls</span>
                    {/* Title 	*/}	
                    <h2 className="h2-md">Keep Your Favorite People Close to You</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                      posuere orci auctor purus euismod an aliquam quaerat purus
                    </p>
                    {/* STORE BADGES */}												
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/tiendas/appstore-white.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/tiendas/googleplay-white.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/tiendas/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/tiendas/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/tiendas/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}	
                  </div>
                </div>	{/* END DOWNLOAD TXT */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END  DOWNLOAD-6 */}
          {/* FAQs-2
			============================================= */}
          <section id="faqs-2" className="wide-100 faqs-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Got Questions? Look Here.</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FAQs-2 QUESTIONS */}	
              <div className="faqs-2-questions pc-15">
                <div className="row">
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #1 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I see NordEx in action before purchasing it?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit in odio integer congue metus vitae arcu mollis blandit 
                          ultrice ligula egestas and magna suscipit lectus magna suscipit luctus blandit vitae
                        </p>
                      </div>	
                      {/* QUESTION #2 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">What are the requirements for using NordEx?</h5>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec ipsum enim an porta justo integer at velna 
                          vitae auctor integer congue undo magna at pretium purus pretium ligula 
                        </p>
                      </div>
                      {/* QUESTION #3 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.8s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I use NordEx on different devices?</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus ligula euismod pretium purus
                              pretium rutrum tempor sapien
                            </p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                              pretium a purus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #4 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Do you have a free trial?</h5>
                        {/* Answer */}
                        <p className="p-lg">Cubilia laoreet augue egestas and luctus donec curabite diam vitae dapibus libero and 
                          quisque gravida donec neque. Blandit justo aliquam molestie nunc sapien justo
                        </p>
                      </div>
                      {/* QUESTION #5 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">How does NordEx handle my privacy?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit sit amet in odio. Integer congue leo metus. Vitae arcu mollis 
                          blandit ultrice ligula
                        </p>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec congue leo metus. Vitae arcu mollis blandit 
                          integer at velna
                        </p>
                      </div>
                      {/* QUESTION #6 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">I have an issue with my account</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus</p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Quaerat sodales sapien euismod blandit purus and ipsum primis in cubilia laoreet 
                              augue luctus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                </div>	{/* End row */}
              </div>	{/* END FAQs-2 QUESTIONS */}	
              {/* MORE QUESTIONS BUTTON */}	
              <div className="row">
                <div className="col-md-12">	
                  <div className="more-questions text-center mt-40">
                    <h5 className="h5-sm"><span className="flaticon-check" /> 
                      Have more questions? <a href="contacts.html" className="skyblue-color">Ask your question here</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>	   {/* End container */}		
          </section>	{/* END FAQs-2 */}
          {/* DOWNLOAD-1
			============================================= */}
          <section id="download-1" className="bg_skyblue bg_pattern_02 pt-100 download-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center m-row">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-bottom">
                  <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-15.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-top">
                  <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Title */}
                    <h2 className="h2-md">Showcase your app with NordEx®</h2>
                    {/* Text */}
                    <p className="p-xl">Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus</p>
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore-white" src="assets/images/tiendas/appstore.png" alt="appstore-logo" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay-white" src="assets/images/tiendas/googleplay.png" alt="googleplay-logo" />
                      </a>
                    </div>	
                    {/* Rating */}
                    <div className="txt-block-rating">
                      <div className="stars-rating">
                        All Versions Rating
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />	
                        <p className="txt-rating">Based on 7.296 user reviews (App Store &amp; Google Play)</p>
                      </div>			
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END DOWNLOAD-1 */}
          {/* BLOG-1
			============================================= */}
          <section id="blog-1" className="wide-60 blog-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Our Tips and Latest News</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* BLOG POSTS */}
              <div className="row">
                {/* BLOG POST #1 */}
                <div id="b-post-1" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-1-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">NordEx News</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien donec gravida ipsum a porta justo vitae</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>12 min read</p>
                      </div>	
                    </div>
                  </div>
                </div>	{/* END  BLOG POST #1 */}
                {/* BLOG POST #2 */}
                <div id="b-post-2" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-2-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Inspiration</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and dolor libero risus</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>8 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #2 */}
                {/* BLOG POST #3 */}
                <div id="b-post-3" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-3-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Tutorials</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien NordEx, donec gravida ipsum a porta justo</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>22 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #3 */}
              </div>	{/* END BLOG POSTS */}
            </div>	   {/* End container */}		
          </section>	{/* END BLOG-1 */}
          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}	
                  <div className="col-lg-6">
                    <div className="newsletter-txt">	
                      {/* Section ID */}	
                      <span className="section-id">Subscribe to Our Newsletter</span>
                      {/* Title */}	
                      <h4 className="h4-xl">Stay up to date with our news, ideas and updates</h4>	
                    </div>								
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">
                        <input type="email" autoComplete="off" className="form-control" placeholder="Your email address" required id="s-email" />								
                        <span className="input-group-btn">
                          <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Subscribe Now</button>
                        </span>										
                      </div>
                      {/* Newsletter Form Notification */}	
                      <label htmlFor="s-email" className="form-notification" />
                    </form>							
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}	
          </section>	{/* END NEWSLETTER-1 */}
         
        </div>	{/* END PAGE CONTENT */}
      
        
            </div>
        )
    }
}
export default Home_6 