import React from 'react';
import { Link } from "react-router-dom";

class Header extends React.Component {
	render() {
		const { colorHeader } = this.props;
		const getColor = () => {
			if (colorHeader)
				return "header dark-menu navbar-dark";
			else
				return "header white-menu navbar-dark";
		}
		return (
			<div>
				<header id="header" className={getColor()}>
					<div className="header-wrapper">
						<div className="wsmobileheader clearfix">
							<span className="smllogo"><img src="assets/images/cofeps/logo.png" alt="mobile-logo" /></span>
							<Link to="#" id="wsnavtoggle" className="wsanimated-arrow"><span></span></Link>
						</div>
						<div className="wsmainfull menu clearfix">
							<div className="wsmainwp clearfix">
								<div className="desktoplogo">
									<Link to="/" className="logo-black"><img src="assets/images/cofeps/logo_blanco.png" alt="header-logo" />
									</Link>
								</div>
								<nav className="wsmenu clearfix">
									<ul className="wsmenu-list nav-rose-hover">
										<li className="nl-simple" aria-haspopup="true">
											<a href={"tel:" + '+593 (0)992114930'} className="btn btn-blue tra-grey-hover last-link">
												Contactar
											</a>&nbsp;&nbsp;
										</li>

									</ul>
								</nav>
							</div>
						</div>
					</div>
				</header>
			</div>
		)
	}
}
export default Header


/**
										<li className="nl-simple" aria-haspopup="true">
											<Link to="/about">¿Quienes somos?</Link>
										</li>

 * 
										<li aria-haspopup="true"><Link to="#">Pages <span className="wsarrow"></span></Link>
											<div className="wsmegamenu clearfix halfmenu">
												<div className="container-fluid">
													<div className="row">

														<ul className="col-lg-6 link-list">
															<li><Link to="features">Features</Link></li>
															<li><Link to="/pricing">Pricing Plans</Link></li>
															<li><Link to="/more_apps">More Apps</Link></li>
															<li><Link to="/faqs">FAQs Page</Link></li>
														</ul>

														<ul className="col-lg-6 link-list">
															<li><Link to="/blog_listing">Blog Listing</Link></li>
															<li><Link to="/single_post">Single Blog Post</Link></li>
															<li><Link to="/terms">Terms & Privacy</Link></li>
															<li><Link to="/contacts">Contact Us</Link></li>
														</ul>

														<ul className="col-lg-6 link-list">
															<li><Link to="/">Home 1</Link></li>
															<li><Link to="/home_2">Home 2</Link></li>
															<li><Link to="/home_3">Home 3</Link></li>
															<li><Link to="/home_4">Home 4</Link></li>
															<li><Link to="/home_5">Home 5</Link></li>
															<li><Link to="/home_6">Home 6</Link></li>
														</ul>

														<ul className="col-lg-6 link-list">
															<li><Link to="/home_7">Home 7</Link></li>
															<li><Link to="/home_8">Home 8</Link></li>
															<li><Link to="/home_9">Home 9</Link></li>
															<li><Link to="/home_10">Home 10</Link></li>
															<li><Link to="/home_11">Home 11</Link></li>
															<li><Link to="/home_12">Home 12</Link></li>
														</ul>
													</div>
												</div>
											</div>
										</li>
 */