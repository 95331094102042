import React from 'react';

class Beneficiarios extends React.Component {
    render() {
        return (
            <>
                {/* CONTENT-3
              ============================================= */}
                <section id="content-3" className="wide-60 content-section division">
                    <div className="container">
                        {/* CONTENT BOX-2 */}
                        <div id="cb-1-2" className="cbox-1">
                            <div className="row d-flex align-items-center m-row">
                                {/* TEXT BLOCK */}
                                <div className="col-md-7 col-lg-6 m-bottom">
                                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                                        {/* CONTENT BOX #1 */}
                                        <div className="cbox mb-30">
                                            {/* Icon */}
                                            <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-video-player-2" /></div>
                                            {/* Text */}
                                            <div className="cbox-txt">
                                                <h5 className="h5-sm">Beneficios</h5>
                                                <p className="p-lg"> Transacciones sin costo adicional | transacciones inmediatas y fáciles
                                                </p>
                                            </div>
                                        </div>
                                        {/* CONTENT BOX #2 */}
                                        <div className="cbox mb-30">
                                            {/* Icon */}
                                            <div className="cbox-ico ico-65 skyblue-color"><span className="flaticon-percentage" /></div>
                                            {/* Text */}
                                            <div className="cbox-txt">
                                                <h5 className="h5-sm">Beneficiarios</h5>
                                                <p className="p-lg">Instituciones financieras aliadas, socios de instituciones aliadas,
                                                    colaboradores de las instituciones aliadas, dueños de establecimientos comerciales
                                                    y sus clientes, organizaciones de la Economía Popular y Solidaria.
                                                </p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>	{/* END TEXT BLOCK */}
                                {/* IMAGE BLOCK */}
                                <div className="col-md-5 col-lg-6 m-top">
                                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                        <img className="img-fluid" src="assets/images/img/img-22.png" alt="content-image" />
                                    </div>
                                </div>
                            </div>  {/* End row */}
                        </div>	{/* END CONTENT BOX-2 */}
                    </div>     {/* End container */}
                </section>	{/* END CONTENT-3 */}
            </>
        )
    }
}
export default Beneficiarios 