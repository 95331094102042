import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { Link } from 'react-router-dom';

class VideoPopup extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        const { ...props } = this.props;
        const { videoId } = props;
        return (
            <>
                <div className="video-preview pc-15">
                    {/* Play Icon */}
                    <div onClick={this.openModal} className="video-popup1" >
                        <div className="video-btn video-btn-lg bg_blue ico-75">
                            <div className="video-block-wrapper green-color"><span className="flaticon-play-button-1" /></div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={videoId} onClose={() => this.setState({ isOpen: false })} />

            </>
        )
    }
}
export default VideoPopup;