import GooglePlay from 'components/GooglePlay';
import PlayStore from 'components/PlayStore';
import React from 'react';

class HeaderHome extends React.Component {
  render() {
    return (
      <>
        {/* HERO-10  */}
        <section id="hero-10" className="bg_fixed bg_pattern_04 hero-section division">
          <div className="container  mb-100">
            <div className="row">
              <div className="col-xl-10 offset-xl-1">
                <div className="row d-flex align-items-center m-row">
                  {/* HERO TEXT */}
                  <div className="col-md-7 col-lg-6 col-xl-6 m-top">
                    <div className="hero-10-txt wow fadeInRight" data-wow-delay="0.6s">
                      {/* App Logo  */}
                      <div className="hero-app-logo text-center">
                        <img className="img-fluid" src="assets/images/icono.png" alt="hero-app-logo" />
                      </div>
                      {/* Title  */}
                      <h2 className="h2-lg">COOPAGOS</h2>
                      <h5 className="h5-lg">Billetera Electrónica Cooperativa</h5>
                      <p className="h5-lg">
                      </p>

                      {/* STORE BADGES */}
                      <div className="stores-badge mb-10">
                        {/* AppStore */}
                        <GooglePlay /> 
                        <PlayStore />
                      </div>	{/* END STORE BADGES */}
                      {/* OS Prerequisite */}
                      <span className="os-version">Disponible para iOS 8 y Dispositivos Android de 5.5</span>
                    </div>
                  </div> 	  {/* END HERO TEXT */}
                  {/* HERO IMAGE */}
                  <div className="col-md-5 col-lg-6 col-xl-6 m-bottom">
                    <div className="hero-10-img wow fadeInLeft" data-wow-delay="0.4s">
                      <img className="img-fluid-hero10" src="assets/images/coopagos/download2.png" alt="hero-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>    {/* End row */}
          </div>     {/* End container */}
        </section>	{/* END HERO-10 */}
      </>
    )
  }
}
export default HeaderHome 