import GooglePlay from 'components/GooglePlay';
import PlayStore from 'components/PlayStore';
import React from 'react';

class Download extends React.Component {
    render() {
        return (
            <>
                <section id="download-1" className="pt-100 download-section division">
                    <div className="container">
                        <div className="row d-flex align-items-center m-row">
                            {/* IMAGE BLOCK */}
                            <div className="col-md-5 col-lg-6 m-bottom">
                                <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">
                                    <img className="img-fluid" src="assets/images/coopagos/home5.png" alt="content-image" />
                                </div>
                            </div>
                            {/* TEXT BLOCK */}
                            <div className="col-md-7 col-lg-6 m-top">
                                <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">
                                    {/* Title */}
                                    <h2 className="h2-md">EMPIEZA AQUÍ <br />
                                        a transaccionar inmediatamente y sin costo</h2>
                                    {/* Text */}
                                    <p className="p-xl">Disponible en plataformas de IOS y Android</p>
                                    {/* STORE BADGES */}
                                    <div className="stores-badge">
                                        <GooglePlay />
                                        <PlayStore />
                                    </div>
                                    {/* Rating
                                    <div className="txt-block-rating">
                                        <div className="stars-rating">
                                            All Versions Rating
                                            <span className="flaticon-star ml-5" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star" />
                                            <span className="flaticon-star-half-empty" />
                                            <p className="txt-rating">Based on 7.296 user reviews (App Store &amp; Google Play)</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>	{/* END TEXT BLOCK */}
                        </div>    {/* End row */}
                    </div>	   {/* End container */}
                </section>	{/* END DOWNLOAD-1 */}

            </>
        )
    }
}
export default Download 