import Header from 'components/Header'
import Footer from 'components/Footer';
import React from 'react'
import { Link } from 'react-router-dom';


class HomeEmpredimiento extends React.Component {
    render() {
        return (
            <>
                <div id="loader-wrapper">
                    <div id="loading">
                        <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
                    </div>
                </div>
                <div id="page" className="page" style={{ backgroundColor: "#FFFFFF" }}>
                    <Header />
                    <div id='inicio'>
                        <img className="img-fluid" src="assets/images/cofeps/emprendimiento/yachana-pagina-cofeps-banner-principal.png" alt="hero-image" />
                    </div>
                    <br />
                    <br />
                    <section id="reviews-1" className="rel wide-100 reviews-section division">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="section-title text-center mb-60">
                                        <h2 className="h2-md">¡Anímate a <strong>emprender</strong>!</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="divider-wrapper text-center">
                                <div className="section-divider" />
                            </div>
                        </div>
                    </section>

                    <section id="content-3" className="mt-30 pt-60 pb-40 content-section division">
                        <div className="container">
                            <br /><br />
                            {/* SECTION TITLE */}
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="section-title text-center mb-70">
                                        {/* Title */}
                                        <h2 className="h2-md">¿Qué es el programa yachana?</h2>
                                        {/* Text */}
                                        <p className="p-xl">Nacemos de la necesidad de formar equipos de emprendedores con base en economía popular y solidaria, fortaleciéndolos con metodologías ágiles.
                                        </p>
                                        <p className="p-xl">
                                            Somos un programa de formación hibrida, con el cual brindamos oportunidades a diversos emprendimientos. A través de metodologías ágiles, potenciamos tu emprendimiento, validamos tus ideas con la finalidad de responder a las necesidades de tu público objetivo. Descubre tu potencial con Yachana.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* CONTENT BOX-1 */}
                            <div id="cb-1-1" className="cbox-1 mt-30 pt-60 pb-40" >
                                <div className="row d-flex align-items-center m-row">
                                    {/* TEXT BLOCK */}
                                    <div className="col-md-7 col-lg-6 m-bottom">
                                        <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                                            {/* Section ID */}
                                            <span className="section-id grey-color">Emprendimiento solidario</span>
                                            {/* Title */}
                                            <h2 className="h2-md">Cuál es nuestro Objetivo: </h2>
                                            {/* List */}
                                            <ul className="simple-list">
                                                <li className="list-item">
                                                    <p className="p-lg">Impulsamos emprendimientos solidarios comprometidos
                                                        con la economía popular y solidaria. Promovemos la sostenibilidad,
                                                        el empleo y el impacto positivo ambiental y social. Juntos, construimos
                                                        redes cooperativas estructuradas en Ecuador.
                                                    </p>
                                                </li>
                                                {/*   <li className="list-item">
                                                    <p className="p-lg">Fortalecer a instituciones que forman farte del ecosistema cooperativo
                                                    </p>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>	{/* END TEXT BLOCK */}
                                    {/* IMAGE BLOCK */}
                                    <div className="col-md-5 col-lg-6 m-top">
                                        <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                            <img className="img-fluid" src="assets/images/cofeps/emprendimiento/yachana-objetivo.png" alt="content-image" />
                                        </div>
                                    </div>
                                </div>  {/* End row */}
                            </div>	{/* END CONTENT BOX-1 */}
                            <div id="cb-1-1" className="cbox-1 mt-30 pt-60 pb-40" >
                                <div className="row d-flex align-items-center m-row">
                                    {/* TEXT BLOCK */}
                                    <div className="col-md-5 col-lg-6 m-top">
                                        <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                            <img className="img-fluid" src="assets/images/cofeps/emprendimiento/YACHANA-PUBLICO.png" alt="content-image" />
                                        </div>
                                    </div>	{/* END TEXT BLOCK */}
                                    {/* IMAGE BLOCK */}
                                    <div className="col-md-7 col-lg-6 m-bottom">
                                        <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                                            <h2 className="h2-md">Nuestro público objetivo</h2>
                                            <p className="p-lg">Nuestro programa de emprendimiento está dirigido a graduados de EDUCOPE y organizaciones mapeadas que cumplan con los siguientes requisitos.
                                            </p>
                                            <ul className="simple-list">
                                                <li className="list-item">
                                                    <p className="p-lg">Buscamos personas con grupos conformados de mínimo 5 integrantes
                                                    </p>
                                                </li>
                                                <li className="list-item">
                                                    <p className="p-lg">Disponibilidad de mínimo 2 horas al día y experiencia previa en emprendimientos.
                                                    </p>
                                                </li>
                                            </ul>
                                            <p className="p-lg">¡Anímate y se parte de esta iniciativa!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-lg-6 m-bottom">
                                        <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                                            <h2 className="h2-md">Lugares de alcance</h2>
                                            {/* List */}
                                            <ul className="simple-list">
                                                <li className="list-item"><p className="p-lg">Azuay</p></li>
                                                <li className="list-item"><p className="p-lg">Cañar</p></li>
                                                <li className="list-item"><p className="p-lg">El Oro</p></li>
                                                <li className="list-item"><p className="p-lg">Guayas</p></li>
                                                <li className="list-item"><p className="p-lg">Santa Elena</p></li>
                                                <li className="list-item"><p className="p-lg">Loja</p></li>
                                                <li className="list-item"><p className="p-lg">Morona Santiago</p></li>
                                                <li className="list-item"><p className="p-lg">Pichincha</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-6 m-top">
                                        <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                            <img className="img-fluid" src="assets/images/cofeps/emprendimiento/yachana-mapa.png" alt="content-image" />
                                        </div>
                                    </div>
                                </div>  {/* End row */}
                            </div>	{/* END CONTENT BOX-1 */}
                            <section id="brands-2" className="wide-70 brands-section division">
                                <div className="container">
                                    {/* BRANDS TITLE */}
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2">
                                            <div className="brands-title text-center">
                                                <h4 className="h4-md">¿Qué organizaciones apoyan al programa?</h4>
                                            </div>
                                        </div>
                                    </div>
                                    {/* BRANDS-2 WRAPPER */}
                                    <div className="brands-2-wrapper">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                {/* BRAND LOGO IMAGE */}
                                                <div className="brand-logo">
                                                    <a target="_blank" href="https://www.jardinazuayo.fin.ec/">
                                                        <img className="img-fluid" src="assets/images/cofeps/emprendimiento/logo-JA.png" alt="brand-logo" />
                                                    </a>
                                                </div>
                                                <div className="brand-logo">
                                                    <a target="_blank" href="">
                                                        <img className="img-fluid" src="assets/images/cofeps/emprendimiento/yachana-logo.png" alt="brand-logo" />
                                                    </a>
                                                </div>
                                                <div className="brand-logo">
                                                    <a target="_blank" href="https://www.isteps.edu.ec/">
                                                        <img className="img-fluid" src="assets/images/cofeps/emprendimiento/logotipo-isteps.png" alt="brand-logo" />
                                                    </a>
                                                </div>
                                                <div className="brand-logo">
                                                    <a target="_blank" href="https://educoope.jardinazuayo.fin.ec/login/index.php">
                                                        <img className="img-fluid" src="assets/images/cofeps/emprendimiento/educoope-logo.png" alt="brand-logo" />
                                                    </a>
                                                </div>
                                                <div className="brand-logo">
                                                    <a target="_blank" href="">
                                                        <img className="img-fluid" src="assets/images/cofeps/emprendimiento/logocofeps.png" alt="brand-logo" />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>  {/* END BRANDS-2 WRAPPER */}
                                </div>     {/* End container */}
                            </section>	{/* END BRANDS-2 */}

                            <br /><br />
                        </div>     {/* End container */}
                    </section>	{/* END CONTENT-3 */}
                    <Footer />
                </div>
            </>
        )
    }
}

export default HomeEmpredimiento