import logo from './logo.svg';
// import './App.css';
import { createBrowserHistory } from "history";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Faqs from './pages/Faqs';
import Features from './pages/Features';
import More_apps from './pages/More_apps';
import Pricing from './pages/Pricing';
import Reviews from './pages/Reviews';
import Single_post from './pages/Single_post';
import Terms from './pages/Terms';
import Demo from './pages/Demo';
import About from './pages/About';
import Blog_listing from './pages/Blog_listing';
import Contacts from './pages/Contacts';
import Home_2 from './pages/Home_2';
import Home_3 from './pages/Home_3';
import Home_4 from './pages/Home_4';
import Home_5 from './pages/Home_5';
import Home_6 from './pages/Home_6';
import Home_7 from './pages/Home_7';
import Home_8 from './pages/Home_8';
import Home_9 from './pages/Home_9';
import Home_10 from './pages/Home_10';
import Home_11 from './pages/Home_11';
import Home_12 from './pages/Home_12';
import Home_Coopagos from './pages/home/Home_Coopagos';
import { BrowserRouter as Router, Redirect, Switch, Routes, Route, Navigate } from "react-router-dom";
import Descarga from './pages/descarga/Descarga';
import Home_Cofeps from 'pages/cofeps/Home_2';
import  HomeEmpredimiento from 'pages/cofeps/emprendimiento/HomeEmpredimiento';

const hist = createBrowserHistory();
const MyRoutes = [
	<Route path="/" element={<Home_Cofeps />} />,
	<Route path="/cofeps" element={<Home_Cofeps />} />,
	<Route path="/emprendimiento" element={<HomeEmpredimiento />} />,
	<Route path="/descarga" element={<Descarga />} />,
	<Route path="/about" element={<About />} />,
	<Route path="/faqs" element={<Faqs />} />,
	<Route path="/features" element={<Features />} />,
	<Route path="/more_apps" element={<More_apps />} />,
	<Route path="/pricing" element={<Pricing />} />,
	<Route path="/reviews" element={<Reviews />} />,
	<Route path="/single_post" element={<Single_post />} />,
	<Route path="/terms" element={<Terms />} />,
	<Route path="/demo" element={<Demo />} />,
	<Route path="/about" element={<About />} />,
	<Route path="/blog_listing" element={<Blog_listing />} />,
	<Route path="/contacts" element={<Contacts />} />,
	<Route path="/home_2" element={<Home_2 />} />,
	<Route path="/home_3" element={<Home_3 />} />,
	<Route path="/home_4" element={<Home_4 />} />,
	<Route path="/home_5" element={<Home_5 />} />,
	<Route path="/home_6" element={<Home_6 />} />,
	<Route path="/home_7" element={<Home_7 />} />,
	<Route path="/home_8" element={<Home_8 />} />,
	<Route path="/home_9" element={<Home_9 />} />,
	<Route path="/home_10" element={<Home_10 />} />,
	<Route path="/home_11" element={<Home_11 />} />,
	<Route path="/home_12" element={<Home_12 />} />,
];

function App() {
	return (
		<div>
			<Router history={hist} basename={process.env.REACT_APP_ROUTER_BASE}>
				<div className="App">
					<Routes>
						{MyRoutes}
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</div>
			</Router>
		</div>
	);
}

export default App;
