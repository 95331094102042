import React, {Component} from 'react';

class Home_11 extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          {/* HEADER
			============================================= */}
          
          {/* HERO-11
			============================================= */}	
          <section id="hero-11" className="bg_shape_01 hero-section division">
            <div className="container white-color">				
              <div className="row d-flex align-items-center">
                {/* HERO TEXT */}
                <div className="col">
                  <div className="hero-11-txt text-center">
                    {/* Title */}
                    <h2 className="h2-lg">Manage all your media files using only <span>NordEx</span>® App</h2>
                    {/* Text */}
                    <p>Feugiat primis ligula risus auctor augue laoreet justo egestas mauris augue egestas and mauris viverra 
                      tortor in iaculis magna feugiat mauris
                    </p>
                    {/* HERO IMAGE */}
                    <div className="hero-11-img">
                      <img className="img-fluid" src="assets/images/hero/hero-11-img.png" alt="video-preview" />
                    </div>
                  </div>
                </div>	{/* END HERO TEXT */}
              </div>	  {/* End row */}
            </div>	   {/* End container */}
            {/* SECTION OVERLAY */}	
            <div className="bg_fixed wave_overlay" />
          </section>	{/* END HERO-11 */}
          {/* FEATURES-3
			============================================= */}
          <section id="features-3" className="pt-100 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Ready to Try NordEx?</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-3 WRAPPER */}	
              <div className="fbox-3-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-3-1" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-3-ico stateblue-color ico-65"><span className="flaticon-roundabout" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Transfer</h5>
                      {/* Text */}
                      <p className="p-md">Porta semper lacus and cursus risus undo feugiat a primis augue sodales</p>
                    </div>
                  </div>
                  {/* FEATURE BOX #2 */}
                  <div id="fb-3-2" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-3-ico stateblue-color ico-65"><span className="flaticon-scale" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Manage</h5>
                      {/* Text */}
                      <p className="p-md">Porta semper lacus and cursus risus undo feugiat a primis augue sodales</p>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-3-3" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-3-ico stateblue-color ico-65"><span className="flaticon-delete" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Delete</h5>
                      {/* Text */}
                      <p className="p-md">Porta semper lacus and cursus risus undo feugiat a primis augue sodales</p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #4 */}
                  <div id="fb-3-4" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="1s">
                      {/* Icon */}
                      <div className="fbox-3-ico stateblue-color ico-65"><span className="flaticon-sync" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Convert</h5>
                      {/* Text */}
                      <p className="p-md">Porta semper lacus and cursus risus undo feugiat a primis augue sodales</p>
                    </div>
                  </div>	
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-3 WRAPPER */}	
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-3 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="wide-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-02.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Perfect Integration</span>
                      {/* Title */}	
                      <h2 className="h2-md">Work smarter with powerful features</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                </div>	  {/* End row */}	
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Handling with Ease</span>
                      {/* Title */}	
                      <h2 className="h2-md">More productivity with less effort</h2>
                      {/* List */}	
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                            luctus magna and luctus and egestas sapien egestas vitae volute
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                            pretium a purus mauris
                          </p>
                        </li>
                      </ul>
                      {/* Icons List */}
                      <ul className="txt-block-ico-list ico-60 stateblue-color">
                        <li><span className="flaticon-picture-1" /></li>
                        <li><span className="flaticon-video-player-1" /></li>
                        <li><span className="flaticon-zip" /></li>
                        <li><span className="flaticon-levels" /></li>
                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-10.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}	
          {/* CONTENT-5
			============================================= */}
          <section id="content-5" className="pb-80 content-section">
            <div className="bg-inner bg_whitesmoke pt-100 division">
              <div className="container">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="content-5-img right-column pc-20 text-center wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img/img-08.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* CONTENT BOX #1 */}
                      <div className="cbox mb-30">
                        {/* Icon */}
                        <div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-tasks" /></div> 
                        {/* Text */}
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Add Notes &amp; Tags</h5>
                          <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                            suscipit quaerat ultrice tellus viverra
                          </p>
                        </div>
                      </div>	
                      {/* CONTENT BOX #2 */}
                      <div className="cbox mb-30">
                        {/* Icon */}
                        <div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-sync" /></div> 
                        {/* Text */}
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Convert Photo &amp; Video</h5>
                          <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                            suscipit quaerat ultrice tellus viverra
                          </p>
                        </div>
                      </div>	
                      {/* CONTENT BOX #3 */}
                      <div className="cbox">
                        {/* Icon */}
                        <div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-image" /></div> 
                        {/* Text */}
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Share Files &amp; Media</h5>
                          <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                            suscipit quaerat ultrice tellus viverra
                          </p>
                        </div>
                      </div>
                    </div>	
                  </div>	{/* END CONTENT TXT */}	
                </div>	  {/* End row */}
              </div>	   {/* End container */}
            </div>		{/* End Inner Background */}	
          </section>	{/* END CONTENT-5 */}
          {/* FEATURES-6
			============================================= */}
          <section id="features-6" className="wide-50 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">More Features to Expect</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-6 WRAPPER */}
              <div className="fbox-6-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-6-1" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-email stateblue-color" />
                        <h5 className="h5-sm">File Manager</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-6-2" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-users stateblue-color" />
                        <h5 className="h5-sm">Contacts Manager</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #3 */}
                  <div id="fb-6-3" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-alarm stateblue-color" />
                        <h5 className="h5-sm">Make Ringtones</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #4 */}
                  <div id="fb-6-4" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="1s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-roundabout stateblue-color" />
                        <h5 className="h5-sm">Convert Media Files</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #5 */}
                  <div id="fb-6-5" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="1.2s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-harddisk stateblue-color" />
                        <h5 className="h5-sm">Files Sharing</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #6 */}
                  <div id="fb-6-6" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="1.4s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-harddisk-1 stateblue-color" />
                        <h5 className="h5-sm">Storage &amp; Backup</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-6 WRAPPER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-6 */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="rel crocus_gradient bg_shape_02 wide-60 content-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}		
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Totally Optimized</span>
                    {/* Title */}	
                    <h2 className="h2-xs">Intuitive features, powerful results</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                    {/* Button */}	
                    <a href="#content-11" className="btn btn-tra-white skyblue-hover">Find Out More</a>
                  </div>	
                </div>	{/* END TEXT BLOCK */}		
                {/* IMAGE BLOCK */}	
                <div className="col-md-6 col-xl-6">
                  <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/tablet.png" alt="content-image" />
                  </div>	
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img/img-01.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Register in 30 Seconds</span>
                    {/* Title */}	
                    <h2 className="h2-md">Lightning fast and super powerful</h2>
                    {/* Text */}	
                    <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                      luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                      sodales a sapien
                    </p>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* STATISTIC-2
			============================================= */}
          <div id="statistic-2" className="pb-60 statistic-section division">
            <div className="container">
              {/* STATISTIC-2 WRAPPER */}
              <div className="statistic-2-wrapper pc-35 text-center">
                <div className="row">
                  {/* STATISTIC BLOCK #1 */}
                  <div id="sb-2-1" className="col-sm-6 col-lg-3">							
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number text-purple-gradient"><span className="count-element">28</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Faster Access</h5>
                    </div>								
                  </div>
                  {/* STATISTIC BLOCK #2 */}
                  <div id="sb-2-2" className="col-sm-6 col-lg-3">										
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number text-purple-gradient"><span className="count-element">47</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">App Productivity</h5>
                    </div>							
                  </div>
                  {/* STATISTIC BLOCK #3 */}
                  <div id="sb-2-3" className="col-sm-6 col-lg-3">							
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number text-purple-gradient"><span className="count-element">54</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Secure Access</h5>	
                    </div>						
                  </div>
                  {/* STATISTIC BLOCK #4 */}
                  <div id="sb-2-4" className="col-sm-6 col-lg-3">							
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="1s">		
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number text-purple-gradient"><span className="count-element">36</span>%</h2>
                      {/* Text */}	
                      <h5 className="h5-xs">Battery Saving</h5>	
                    </div>						
                  </div>
                </div>	{/* End row */}
              </div>	{/* END STATISTIC-2 WRAPPER */}
            </div>	 {/* End container */}		
          </div>	 {/* END STATISTIC-2 */}
          {/* TESTIMONIALS-2
			============================================= */}
          <section id="reviews-2" className="reviews-section division">
            <div className="container">
              <div className="row">
                {/* TESTIMONIALS TITLE */}
                <div className="col-lg-4">
                  <div className="reviews-2-title pc-10">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Reviews</span>
                    {/* Title */}
                    <h2 className="h2-md mb-20">Our Happy Customers</h2>
                    {/* Text */}	
                    <p className="p-lg">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and 
                      cursus varius
                    </p>
                  </div>
                </div>
                {/* TESTIMONIALS CAROUSEL */}
                <div className="col-lg-8">					
                  <div className="owl-carousel owl-theme reviews-2-wrapper">
                    {/* TESTIMONIAL #1 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Great Flexibility!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Etiam sapien sem at sagittis congue an augue massa varius egestas undo 
                          suscipit magna tempus undo aliquet				   
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Scott Boxer</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #1 */}
                    {/* TESTIMONIAL #2 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Simple and Useful!</h5>	
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">At sagittis congue augue undo egestas magna ipsum vitae purus and ipsum 
                          primis suscipit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Wendy T.</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #2 */}
                    {/* TESTIMONIAL #3 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">I love this App!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Mauris donec ociis magnis and sapien etiam sapien congue undo augue pretium 
                          and ligula augue a lectus aenean magna
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- pebz13</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #3 */}
                    {/* TESTIMONIAL #4 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-1" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Best App for iOS!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">An augue in cubilia laoreet magna and suscipit egestas magna ipsum purus ipsum 
                          and suscipit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Scott Boxer</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #4 */}
                    {/* TESTIMONIAL #5 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Awesome Design!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Mauris donec magnis sapien undo etiam sapien and congue augue egestas ultrice 
                          a vitae purus velna integer tempor
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- John Sweet</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #5 */}
                    {/* TESTIMONIAL #6 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Simply Amazing App!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">An augue cubilia laoreet undo magna a suscipit undo egestas magna ipsum ligula 
                          vitae purus ipsum primis cubilia blandit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Leslie D.</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #6 */}
                    {/* TESTIMONIAL #7 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Powerful Features!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Augue egestas volutpat and egestas augue in cubilia laoreet magna undo 
                          suscipit luctus
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Marisol19</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #7 */}
                    {/* TESTIMONIAL #8 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Super Support!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis libero tempus 
                          at blandit posuere varius magna
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- AJ</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #8 */}
                  </div>
                </div>	{/* END TESTIMONIALS CAROUSEL */} 
              </div>	
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-2 */}
          {/* BRANDS-2
			============================================= */}
          <section id="brands-2" className="wide-60 brands-section division">
            <div className="container">	
              {/* BRANDS TITLE */}
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="brands-title text-center">
                    <h4 className="h4-xs">Trusted by companies large and small: </h4>
                  </div>
                </div>
              </div>
              {/* BRANDS-2 WRAPPER */}
              <div className="brands-2-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-1.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-2.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-3.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-4.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-5.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-6.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-7.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-8.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-9.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand/brand-10.png" alt="brand-logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>  {/* END BRANDS-2 WRAPPER */}
            </div>     {/* End container */}
          </section>	{/* END BRANDS-2 */}
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_mobile wide-100 download-section division">
            <div className="container white-color">
              <div className="row">	
                {/* DOWNLOAD TXT */}	
                <div className="col-lg-6">	
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Powerful Settings</span>
                    {/* Title 	*/}	
                    <h2 className="h2-md">Take Full Advantage of Smart Features</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                      posuere orci auctor purus euismod an aliquam quaerat purus
                    </p>
                    {/* STORE BADGES */}												
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/tiendas/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/tiendas/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/tiendas/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/tiendas/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/tiendas/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}	
                  </div>
                </div>	{/* END DOWNLOAD TXT */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END  DOWNLOAD-6 */}
          {/* PRICING-2
			============================================= */}
          <section id="pricing-2" className="wide-100 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Simple and Flexible Pricing</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row pricing-row">
                {/* FREE PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">NordEx Free</h5>									
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">0</span>
                      <sup className="validity dark-color"><span>.00</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button  */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Download Now</a>
                    </div>	
                  </div>
                </div>	{/* END FREE PLAN */}
                {/* MONTHLY PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">Monthly Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">6</span>
                      <sup className="validity dark-color"><span>.25</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END MONTHLY PLAN  */}
                {/* ANNUAL PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table rel mb-40 wow fadeInUp" data-wow-delay="0.8s">
                    {/* Hightlight Badge */}
                    <div className="badge-wrapper">
                      <div className="highlight-badge bg_skyblue white-color">
                        <h6 className="h6-sm">Save up to 25%</h6>
                      </div>
                    </div>	
                    {/* Plan Price  */}
                    <div className="pricing-plan highlight bg_whitesmoke">
                      <h5 className="h5-xs">Annual Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">5</span>
                      <sup className="validity dark-color"><span>.69</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END ANNUAL PLAN */}
              </div>	{/* END PRICING TABLES */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                    {/* Button */}
                    <a href="pricing.html" className="btn btn-md btn-tra-grey skyblue-hover mb-10">Start Free 14-day Trial</a>
                    {/* OS Prerequisite */}
                    <span className="os-version">Request OS X 10.10 or later</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}
          {/* FAQs-1
			============================================= */}
          <section id="faqs-1" className="bg_whitesmoke faqs-section division">				
            <div className="container">
              <div className="row">
                {/* FAQs TITLE */}
                <div className="col-lg-4">
                  <div className="faqs-1-title pc-10">
                    {/* Section ID */}	
                    <span className="section-id grey-color">FAQs</span>
                    {/* Title */}
                    <h2 className="h2-md mb-20">Common Questions</h2>
                    {/* Text */}	
                    <p className="p-lg">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and 
                      cursus varius
                    </p>
                  </div>
                </div>
                {/* QUESTIONS ACCORDION */}
                <div className="col-lg-8">
                  <div id="accordion" role="tablist" className="pc-20">
                    {/* QUESTION #1 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingOne">
                        <h5 className="h5-sm">
                          <a data-toggle="collapse" href="#collapseOne" role="button" aria-expanded="true" aria-controls="collapseOne">
                            Can I see NordEx in action before purchasing it?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}	
                          <p className="p-lg">Maecenas gravida porttitor quis vehicula magna luctus tempor. Quisque vel laoreet 
                            turpis. Viverra urna augue, a augue dictum tempor. Sed pulvinar nibh consectetur varius viverra. 
                            Laoreet augue ac massa lorem nulla
                          </p>	
                        </div>
                      </div>
                    </div>	{/* END QUESTION #1 */}
                    {/* QUESTION #2 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingTwo">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false" aria-controls="collapseTwo">
                            I’ve got iPhone 5. Is NordEx compatible with it?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}	
                          <p className="p-lg">Sagittis congue augue egestas volutpat egestas magna suscipit egestas magna ipsum 
                            vitae purus an efficitur ipsum primis in cubilia laoreet augue egestas luctus donec curabitur 
                            dapibus libero tempor
                          </p>
                        </div>
                      </div>
                    </div>	{/* END QUESTION #2 */}
                    {/* QUESTION #3 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingThree">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseThree" role="button" aria-expanded="false" aria-controls="collapseThree">
                            What are the requirements for using NordEx?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}	
                          <p className="p-lg">Sagittis congue augue egestas volutpat egestas magna suscipit egestas and magna 
                            ipsum vitae purus and efficitur ipsum primis in cubilia laoreet
                          </p>
                          {/* Text */}	
                          <p className="p-lg">Sapien egestas, congue gestas posuere cubilia congue ipsum mauris lectus laoreet 
                            gestas neque vitae auctor eros dolor luctus placerat a magna cursus congue magna nihil mpedit 
                            ligula sem congue tempor gravida
                          </p>
                        </div>
                      </div>
                    </div>	  {/* END QUESTION #3 */}
                    {/* QUESTION #4 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingFour">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseFour">   		
                            How does NordEx handle my privacy?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseFour" className="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}
                          <p className="p-lg">An augue cubilia laoreet and magna suscipit egestas magna ipsum purus ipsum primis 
                            and augue ultrice ligula egestas suscipit lectus gestas integer congue a lectus porta tristique phasellus neque blandit and tristique
                          </p> 
                        </div>
                      </div>
                    </div>	  {/* END QUESTION #4 */}
                    {/* QUESTION #5 */}
                    <div className="card last-card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingFive">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseFive">   		
                            How can I cancel my account?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseFive" className="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}
                          <p className="p-lg">Curabitur ac dapibus libero. Quisque eu congue tristique neque. Phasellus blandit 
                            tristique justo undo aliquam. Aliquam vitae molestie nunc. Quisque sapien justo, aliquet non 
                            molestie sed purus, venenatis sem tempor
                          </p> 
                        </div>
                      </div>
                    </div>	  {/* END QUESTION #5 */}
                  </div>	{/* END ACCORDION */}
                  {/* MORE QUESTIONS BUTTON */}		
                  <div className="more-questions pc-20 mt-35">
                    <h5 className="h5-sm"><span className="flaticon-check" /> 
                      Have more questions? <a href="contacts.html" className="skyblue-color">Ask your question here</a>
                    </h5>
                  </div>
                </div>  {/* End col-x */}	
              </div>	{/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END FAQs-1 */}	
          {/* BLOG-1
			============================================= */}
          <section id="blog-1" className="wide-60 blog-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Read Our Tips &amp; Tricks</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* BLOG POSTS */}
              <div className="row">
                {/* BLOG POST #1 */}
                <div id="b-post-1" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-1-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">NordEx News</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien donec gravida ipsum a porta justo vitae</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>12 min read</p>
                      </div>	
                    </div>
                  </div>
                </div>	{/* END  BLOG POST #1 */}
                {/* BLOG POST #2 */}
                <div id="b-post-2" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-2-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Inspiration</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and dolor libero risus</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>8 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #2 */}
                {/* BLOG POST #3 */}
                <div id="b-post-3" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-3-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Tutorials</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien NordEx, donec gravida ipsum a porta justo</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>22 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #3 */}
              </div>	{/* END BLOG POSTS */}
            </div>	   {/* End container */}		
          </section>	{/* END BLOG-1 */}
          {/* DOWNLOAD-3
			============================================= */}
          <section id="download-3" className="bg_purple_img download-section division">
            <div className="container">
              <div className="row">
                {/* DOWNLOAD TXT */}
                <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                  <div className="download-3-txt pc-25 text-center">
                    {/* Title */}
                    <h2 className="h2-sm white-color">Getting started will take no more than 3 minutes</h2>
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/tiendas/appstore-white.png" alt="appstore-logo" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/tiendas/googleplay-white.png" alt="googleplay-logo" />
                      </a>
                    </div>	
                    {/* Small Text */}
                    <p className="p-md white-color">Available for iOS 8 and Android Devices From 5.5</p>
                  </div>
                </div>	{/* END DOWNLOAD TXT */}
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END DOWNLOAD-3 */}
          
        </div>	{/* END PAGE CONTENT */}
     
        
            </div>
        )
    }
}
export default Home_11 