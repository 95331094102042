import React from 'react';

class Coopagos extends React.Component {
    render() {
        return (
            <>
                {/* TESTIMONIALS-2
              ============================================= */}
                <section id="reviews-2" className="reviews-section division mt-100">
                    <div className="container">
                        <div className="row">
                            {/* TESTIMONIALS TITLE */}
                            <div className="col-lg-4">
                                <div className="reviews-2-title pc-10">
                                    {/* Section ID */}
                                    <span className="section-id grey-color">COOPAGOS</span>
                                    {/* Title */}
                                    <h2 className="h2-md mb-20">Billetera Electrónica Cooperativa</h2>
                                    {/* Text */}
                                    <p className="p-lg">
                                        Disfruta de interfaces intuitivas, facil mantenimiento de cuentas de ahorro, registro inmediato y seguro, además de sin costos de transacción
                                    </p>
                                </div>
                            </div>
                            {/* TESTIMONIALS CAROUSEL */}
                            <div className="col-lg-8">
                                <div className="owl-carousel owl-theme reviews-2-wrapper">
                                    <div className="review-2 radius-08">
                                        {/* Title */}
                                        <h5 className="h5-sm">Cambia tu cuenta de ahorros en cualquier momento</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                                <img className="img-fluid" src="assets/images/coopagos/cuentas.jpeg" alt="content-image" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* TESTIMONIAL #1 */}
                                    <div className="review-2 radius-08">
                                        {/* Title */}
                                        <h5 className="h5-sm">Registro rápido y seguro</h5>
                                        {/* Testimonial Text */}
                                        <div className="review-2-txt">
                                            <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                                <img className="img-fluid" src="assets/images/coopagos/registro_1.jpg" alt="content-image" />
                                            </div>
                                        </div>
                                    </div>	{/* END TESTIMONIAL #1 */}
                                    {/* TESTIMONIAL #2 */}
                                    <div className="review-2 radius-08">
                                        {/* Title */}
                                        <h5 className="h5-sm">Consulta de saldos</h5>
                                        <div className="review-2-txt">
                                            <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                                <img className="img-fluid" src="assets/images/coopagos/saldos.jpg" alt="content-image" />
                                            </div>
                                        </div>
                                    </div>	{/* END TESTIMONIAL #2 */}
                                    {/* TESTIMONIAL #3 */}
                                    <div className="review-2 radius-08">
                                        {/* Title */}
                                        <h5 className="h5-sm">Pagos</h5>
                                        <div className="review-2-txt">
                                            <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                                <img className="img-fluid" src="assets/images/coopagos/pago.jpg" alt="content-image" />
                                            </div>
                                        </div>
                                    </div>	{/* END TESTIMONIAL #3 */}
                                    {/* TESTIMONIAL #4 */}
                                    <div className="review-2 radius-08">
                                        {/* Title */}
                                        <h5 className="h5-sm">Cobros</h5>
                                        <div className="review-2-txt">
                                            <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                                                <img className="img-fluid" src="assets/images/coopagos/cobro.jpg" alt="content-image" />
                                            </div>
                                        </div>
                                    </div>	{/* END TESTIMONIAL #4 */}
                                </div>
                            </div>	{/* END TESTIMONIALS CAROUSEL */}
                        </div>
                    </div>     {/* End container */}
                </section>	{/* END TESTIMONIALS-2 */}
            </>
        )
    }
}
export default Coopagos 