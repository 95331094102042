import CustomPage from 'components/CustomPage';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Coopagos from 'pages/home/components/Coopagos';
import React, { Component } from 'react';
import LineasCofeps from './LineasCofeps';
import { Link } from 'react-router-dom';

class Home_Cofeps extends React.Component {
  render() {
    return (
      <>

        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}
        <div id="page" className="page">
          {/* HEADER
			============================================= */}

          <Header />


          {/* HERO-11
			============================================= */}
          <section id="hero-11" className="pb-30 bg_shape_01 hero-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* HERO TEXT */}
                <div className="col">
                  <div className="hero-11-txt text-center">
                    {/* Title */}
                    {/* HERO IMAGE */}
                    <div className="hero-11-img">
                      <br /><br />
                      <img className="img-fluid" src="assets/images/cofeps/logo_blanco.png" alt="video-preview" />
                      <br /><br /><br />
                      {/* Text */}
                      <h2>Corporación de Fomento al Emprendimiento Productivo y Solidario
                      </h2>
                      <br /><br /><br />
                    </div>

                  </div>
                </div>	{/* END HERO TEXT */}
              </div>	  {/* End row */}
            </div>	   {/* End container */}
            {/* SECTION OVERLAY */}
            <div className="bg_fixed wave_overlay" />
          </section>	{/* END HERO-11 */}


          {/* CONTENT-13 ============================================= */}
          <section id="content-3" className="mt-50 pt-80 pb-60 content-section division">
            <div className="container">
              <br /><br />
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">¿Quienes somos?</h2>
                    {/* Text */}
                    <p className="p-xl">Somos una Corporación Civil de derecho privado sin fines de lucro. Buscamos fomentar emprendimientos productivos de distintas áreas y sectores,
                      generamos estrategias de fortalecimiento, mediante asesoria técnica y legal,
                      además de proyectos orientados a contribuir al desarrollo humano, social y económico de sus participantes.

                    </p>
                  </div>
                </div>
              </div>
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}
                      <span className="section-id grey-color">COFEPS</span>
                      {/* Title */}
                      <h2 className="h2-md">Nuestros objetivos</h2>
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Desarrollar servicios y productos a medida, contribuyendo siempre a la formacion de una red cooperativa
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Fortalecer a instituciones que forman farte del ecosistema cooperativo
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/cofeps/cofeps2.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}
              </div>	{/* END CONTENT BOX-1 */}
              <br /><br />
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}






          {/* STATISTIC-2
			============================================= */}
          <div id="statistic-2" className="pt-70   bg_purple_img statistic-section division">
            <div className="container white-color">
              {/* STATISTIC-2 WRAPPER */}
              <div className="statistic-2-wrapper pc-35 text-center">
                <div className="row">
                  {/* STATISTIC BLOCK #1 */}
                  <div id="sb-2-1" className="col-sm-6 col-lg-3">
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">5</span></h2>
                      {/* Text */}
                      <h5 className="h5-xs">Cooperativas Socias</h5>
                    </div>
                  </div>
                  {/* STATISTIC BLOCK #2 */}
                  <div id="sb-2-2" className="col-sm-6 col-lg-3">
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">6</span></h2>
                      {/* Text */}
                      <h5 className="h5-xs">Cooperativas en la Red Coopagos</h5>
                    </div>
                  </div>
                  {/* STATISTIC BLOCK #3 */}
                  <div id="sb-2-3" className="col-sm-6 col-lg-3">
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">12</span></h2>
                      {/* Text */}
                      <h5 className="h5-xs">Fortalecimientos</h5>
                    </div>
                  </div>
                  {/* STATISTIC BLOCK #4 */}
                  <div id="sb-2-4" className="col-sm-6 col-lg-3">
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="1s">
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">4</span></h2>
                      {/* Text */}
                      <h5 className="h5-xs">Servicios Implementados</h5>
                    </div>
                  </div>
                </div>	{/* End row */}
              </div>	{/* END STATISTIC-2 WRAPPER */}
            </div>	 {/* End container */}
          </div>	 {/* END STATISTIC-2 */}


          {/* LINEAS DE TRABAJO ============================================= */}
          <section id="features-5" className="pt-50 pb-80  features-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <br /><br /> <br /><br />
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">Cuales son nuestras lineas de trabajo</h2>
                    {/* Text */}
                    <p className="p-xl">Contamos con un equipo interdiciplinario que cubre diferentes lineas y especializaciones.
                    </p>
                  </div>
                </div>
              </div>
              {/* FEATURES-5 WRAPPER */}
              <div className="fbox-5-wrapper pc-30">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-5-1" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-call" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Servicios Financieros</h5>
                        {/* Text */}
                        <p className="p-lg">Desarrollar soluciones tecnológicas y comerciales para organizaciones aliadas, actores de la economía solidaria y de triple impacto (social, económica y ambiental).
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #2 */}
                  <div id="fb-5-2" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-layers" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Servicios no Financieros</h5>
                        {/* Text */}
                        <p className="p-lg">Son soluciones tecnológicas orientadas a mejorar la capacidad competitiva de las organizaciones, mediante la automatización y/o digitalización de procesos.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-5-3" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-analytics" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Fortalecimiento a las organizaciones de la Economia Solidaria</h5>
                        {/* Text */}
                        <p className="p-lg">Fortalecer de manera integral a las organizaciones de la Economía solidaria, con la finalidad de que generen un aporte real al ecosistema empresarial alternativo.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #4 */}
                  <div id="fb-5-4" className="col-md-6">
                    <Link to="/emprendimiento">
                        <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1s">
                          {/* Icon */}
                          <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-tasks" /></div>
                          {/* Text */}
                          <div className="fbox-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Emprendimiento</h5>
                            {/* Text */}
                            <p className="p-lg">Programa enfocado a promover y fomentar emprendimientos solidarios y asociativos sostenibles, articulando equipos de socios/as trabajadores/as comprometidos con los principios de la EPS.
                            </p>
                          </div>
                        </div>
                    </Link>/
                  </div>
                </div>  {/* End row */}
              </div>	{/* END FEATURES-5 HOLDER */}
            </div>	   {/* End container */}
          </section>	{/* END FEATURES-5 */}


          {/* CONTENT-5 ============================================= */}
          <section id="content-5" className=" content-section">
            <div className="bg-inner bg_sapphire bg_pattern_02  division">
              <div className="container white-color">
                <div className="row d-flex align-items-center">
                  <div className="col-md-7 col-lg-6">
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className=" bg_img_text left-column  wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}
                      <span className="section-id">COFEPS</span>
                      {/* Title */}
                      <h2 className="h2-md">Asesoria personalizada</h2>
                      {/* Text */}
                      <p className="p-lg">Según tus necesidades, buscamos las mejores soluciones y te hacemos una propuesta personalizada, ya sea de desarrollo de software, formación, acompañamiento, etc.
                        Para prestar todos estos servicios, contamos con una amplia Red de Expertos que pueden dar respuesta a cualquiera de tus necesidades.
                      </p>
                    </div>
                  </div>	{/* END CONTENT TXT */}
                </div>	  {/* End row */}
              </div>	   {/* End container */}
            </div>		{/* End Inner Background */}
          </section>	{/* END CONTENT-5 */}
          <section id="more-apps-1" className="bg_whitesmoke wide-70 moreapps-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">Sistemas implementados</h2>
                    {/* Text */}
                    <p className="p-xl">Ponemos a disposición de las instituciones un catálogo de servicios y productos, implementados por nuestro equipo.
                    </p>
                  </div>
                </div>
              </div>
              {/* MORE APPS-1 WRAPPER */}
              <div className="abox-1-wrapper">
                <div className="row">
                  {/* APP #1 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                    <a target="_blank" href="https://instagram.com/coopagos?igshid=YmMyMTA2M2Y=">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}
                        <div className="app-version"><span>1.1.2</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">
                          <img className="img-fluid" src="assets/images/cofeps/cofeps/11.png" alt="app-logo" />
                        </div>
                        {/* Text */}
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                          <h5 className="h5-xs">COOPAGOS</h5>
                          <p>Billetera Electronica Cooperativa</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #1 LINK */}
                  {/* APP #2 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <a target="_blank" href="https://instagram.com/elake.ec?igshid=YmMyMTA2M2Y=">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}
                        <div className="app-version"><span>1.2.0</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">
                          <img className="img-fluid" src="assets/images/cofeps/cofeps/14.png" alt="app-logo" />
                        </div>
                        {/* Text */}
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                          <h5 className="h5-xs">ELAKE</h5>
                          <p>Sistema de comercialización virtual cooperativo</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #2 LINK */}
                  {/* APP #3 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}
                        <div className="app-version"><span>1.0.0</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">
                          <img className="img-fluid" src="assets/images/cofeps/cofeps/15.png" alt="app-logo" />
                        </div>
                        {/* Text */}
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">
                          <h5 className="h5-xs">COOPFIN</h5>
                          <p>Core financiero</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #3 LINK */}
                </div>    {/* End row */}
              </div>	{/* END MORE APPS-1 WRAPPER */}
            </div>	   {/* End container */}
          </section>	{/* END MORE APPS-1 */}


          {/* CONTENT-14
			=============================================  
          <section id="content-14" className="pb-80  whitesmoke_shape content-section division">
            <div className="container"> 
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-40">	 
                    <h2 className="h2-md">Nuestro equipo y servicios!</h2> 
                    <p className="p-xl">  </p>
                  </div>	
                </div>
              </div> 
              <div className="row text-center " >
                <div className="col-md-6">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/8.jpg" alt="content-image" />
                    <p className="p-xl">  <br/> </p> 
                  </div>
                </div>	
                <div className="col-md-6">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/3.jpg" alt="content-image" />
                    <p className="p-xl">  <br/> </p> 
                  </div>
                </div>	 
                 <div className="col-md-6">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/1.jpg" alt="content-image" />
                    <p className="p-xl">   </p> 
                  </div>
                </div>
                	 <div className="col-md-6">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/4.jpg" alt="content-image" />
                    <p className="p-xl">   </p> 
                  </div>
                </div>	
                <div className="col-md-4">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/11.jpg" alt="content-image" />
                    <p className="p-xl">   </p> 
                  </div>
                </div>	
                <div className="col-md-4">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/14.jpg" alt="content-image" />
                    <p className="p-xl">  </p> 
                  </div>
                </div>	
                <div className="col-md-4">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-linea" src="assets/images/cofeps/cofeps/15.jpg" alt="content-image" />
                    <p className="p-xl">  </p> 
                  </div>
                </div>	
              </div>
            </div>    
          </section>	 
          */}

          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_mobile wide-100 download-section division">
            <div className="container white-color">
              <div className="row">
                {/* DOWNLOAD TXT */}
                <div className="col-lg-6">
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="section-id">COFEPS</span>
                    {/* Title 	*/}
                    <h2 className="h2-md">Nuestro equipo, nuestra mejor inversión! </h2>
                    {/* Text */}
                    <p className="p-xl">
                    </p>
                  </div>
                </div>	{/* END DOWNLOAD TXT */}
              </div>    {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END  DOWNLOAD-6 */}

          {/* BRANDS-2
			============================================= */}
          <section id="brands-2" className="  wide-70 brands-section division">
            <div className="container">
              {/* BRANDS TITLE */}
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="brands-title text-center">
                    <h4 className="h4-md">Cooperativas socias</h4>
                  </div>
                </div>
              </div>
              {/* BRANDS-2 WRAPPER */}
              <div className="brands-2-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a target="_blank" href="https://www.jardinazuayo.fin.ec/">
                        <img className="img-fluid" src="assets/images/cofeps/logo/1.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a target="_blank" href="https://www.isteps.edu.ec/">
                        <img className="img-fluid" src="assets/images/cofeps/logo/2.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a target="_blank" href="https://www.facebook.com/CoopJardinEsperanza">
                        <img className="img-fluid" src="assets/images/cofeps/logo/4.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a target="_blank" href="https://www.facebook.com/AyniArtesaniasCuenca/">
                        <img className="img-fluid" src="assets/images/cofeps/logo/3.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a target="_blank" href="https://www.facebook.com/Horizontes.constructores">
                        <img className="img-fluid" src="assets/images/cofeps/logo/5.png" alt="brand-logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>  {/* END BRANDS-2 WRAPPER */}
            </div>     {/* End container */}
          </section>	{/* END BRANDS-2 */}


          {/* FOOTER-1
			============================================= */}
          <Footer />

        </div>	{/* END PAGE CONTENT */}


      </>
    )
  }
}
export default Home_Cofeps