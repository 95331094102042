import CustomPage from 'components/CustomPage';
import GooglePlay from 'components/GooglePlay';
import PlayStore from 'components/PlayStore';
import Download from 'pages/home/components/Download';
import InstitucionesAliadas from 'pages/home/components/InstitucionesAliadas';
import React from 'react';

class Descarga extends React.Component {
  render() {
    return (
      <CustomPage colorHeader>
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT  */}
        <div id="page" className="page">
          <section id="hero-8" className="bg_fixed hero-section division">
            <div className="container">
              <div className="row d-flex align-items-center m-row">
                {/* HERO TEXT */}
                <div className="col-lg-6 col-xl-6 m-bottom">
                  <div className="hero-8-txt pc-25 wow fadeInRight" data-wow-delay="0.6s">
                    {/* Text */}
                    <br/>
                    <h4 className="h4-sm">COOPAGOS</h4>
                    <h2 className="h2-sm">Billetera Electrónica Cooperativa</h2>
                    <h5 className="h5-xl"> ¡Transacciona sin costos de comisión y de manera inmediata!</h5> 
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                        <GooglePlay /> 
                        <PlayStore />
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/tiendas/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/tiendas/macstore.png" alt="macstore-badge" />
									</a> */}
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/tiendas/microsoft.png" alt="microsoft-badge" />
									</a> */}
                    </div>	{/* END STORE BADGES */}
                  </div>
                </div>	{/* END HERO TEXT */}
                {/* HERO IMAGE */}
                <div className="col-lg-6 col-xl-6 11offset-xl-1 m-top">
                  <div className=" pr-20 text-center wow fadeInDown" data-wow-delay="0.4s">
                    <img className="img-fluid" src="assets/images/coopagos/header-download.png" alt="hero-image" />
                  </div>
                </div>
              </div>    {/* End row */}
            </div>	   {/* End container */}
          </section>  
        </div>	{/* END PAGE CONTENT */} 
          <InstitucionesAliadas />
      </CustomPage>
    )
  }
}
export default Descarga 