import VideoPopup from 'components/VideoPopup';
import React from 'react';

class Formulario extends React.Component {
    render() {
        return (
            <>
                <section id="content-13" className="bg_dark bg_chat wide-60 content-section division">
                    <div className="container white-color">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6 col-xl-6">
                                <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s"> 
                                </div>
                                <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                                    <img className="img-fluid" width={"100%"} src="assets/images/coopagos/dudas-atendidas.png" alt="content-image" />
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-6">
                                <div className="form-holder wow fadeInLeft">
                                    <form name="contactform" className="row contact-form">
                                        <div id="input-email" className="col-md-12">
                                            <p className="p-lg">Mail: </p>
                                            <input type="text" name="email" className="form-control email" placeholder="Correo electrónico válido*" />
                                        </div>
                                        {/* Contact Form Input */}
                                        <div id="input-name" className="col-md-12">
                                            <p className="p-lg">Nombres y apellidos: </p>
                                            <input type="text" name="name" className="form-control name" placeholder="Sus nombres completos*" />
                                        </div>
                                        {/* Form Select */}
                                        <div id="input-subject" className="col-md-12 input-subject">
                                            <p className="p-lg">Desde donde nos contactas? </p>
                                            <select id="inlineFormCustomSelect1" name="Subject" className="custom-select subject">
                                                <option>Pertenezco a una organización</option>
                                                <option>Socio final</option>
                                                <option>Otro</option>
                                            </select>
                                        </div>
                                        <div id="input-message" className="col-md-12 input-message">
                                            <p className="p-md">Explique a detalle su inquietud o comentario: </p>
                                            <textarea className="form-control message" name="message" rows={3} placeholder="Me gustaria saber mas sobre el servicio..." defaultValue={""} />
                                        </div>
                                        {/* Contact Form Button */}
                                        <div className="col-md-12 mt-15 form-btn text-right">
                                            <button type="submit" className="btn btn-green tra-skyblue-hover submit">Conectar</button>
                                        </div>
                                        {/* Contact Form Message */}
                                        <div className="col-lg-12 contact-form-msg">
                                            <span className="loading" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>	  {/* End row */}
                    </div>	   {/* End container */}
                </section>	{/* END CONTENT-13 */}
            </>
        )
    }
}
export default Formulario 