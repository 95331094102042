import React, {Component} from 'react';

class Home extends React.Component {
    render() {
        return(
            <div>
        


        <div id="loader-wrapper">
			<div id="loading">
				<span className="cssload-loader"><span className="cssload-loader-inner"></span></span>
			</div>
		</div>

		<div id="page" className="page">
			<section id="hero-1" className="purple_gradient bg_shape_01 hero-section division">
				<div className="container white-color">		
					<div className="row d-flex align-items-center">

						<div className="col-md-7 col-lg-6">
							<div className="hero-1-txt pl-25 wow fadeInRight" data-wow-delay="0.6s">

								<div className="hero-app-logo"><img className="img-fluid" src="assets/images/logo/app-logo.png" alt="hero-app-logo"/></div>

								
								<h2 className="h2-title-xs">Spend Your Time with Loved Ones</h2>

							
								<p>An enim nullam tempor sapien and gravida donec ipsum undo pretium porta integer justo velna</p>
								
																		
								<div className="stores-badge">

									<a href="#" className="store">
										<img className="appstore" src="assets/images/tiendas/appstore.png" alt="appstore-badge" />
									</a>
													
							
									<a href="#" className="store">
										<img className="googleplay" src="assets/images/tiendas/googleplay.png" alt="googleplay-badge" />
									</a> 
												
								
								
								
								</div>

							</div>
						</div>


						
						<div className="col-md-5 col-lg-6">	
							<div className="hero-1-img text-center wow fadeInLeft" data-wow-delay="0.6s">
								<img className="img-fluid" src="assets/images/hero/hero-1-img.png" alt="hero-image"/>
							</div>
						</div>	


					</div> 								
				</div>	


				
				<div className="bg_fixed incline_overlay"></div>


			</section>




			<section id="features-4" className="pt-50 features-section division">
				<div className="container">


				
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-70">		

							
								<h2 className="h2-md">Quick, Easy and Secure</h2>	

							
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


			
			 		<div className="fbox-4-wrapper">
			 			<div className="row">


		 				
		 					<div id="fb-4-1" className="col-md-6">
		 						<div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.4s">
		 							<div className="row d-flex align-items-center m-row">

										
										<div className="col-lg-8 m-bottom">
											<div className="fbox-4-txt">
											
											
												<h5 className="h5-sm">Quick Access</h5>

											
												<p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales 
													a sapien magna at vitae congue tempus
												</p>

											</div>
										</div>

									
										<div className="col-lg-4 m-top">
											<div className="fbox-4-ico ico-100 text-center">
												<span className="flaticon-call"></span>
											</div>
										</div>

									</div>
		 						</div> 
		 					</div>

		 				
		 					<div id="fb-4-2" className="col-md-6">
		 						<div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.6s">
		 							<div className="row d-flex align-items-center m-row">

										
										<div className="col-lg-8 m-bottom">
											<div className="fbox-4-txt">
											
												
												<h5 className="h5-sm">Group Messaging</h5>

												
												<p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales 
													a sapien magna at vitae congue tempus
												</p>

											</div>
										</div>

									
										<div className="col-lg-4 m-top">
											<div className="fbox-4-ico ico-100 text-center">
												<span className="flaticon-user-1"></span>
											</div>
										</div>

									</div>	
		 						</div>  
		 					</div>	


		 			
		 					<div id="fb-4-3" className="col-md-6">
		 						<div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="0.8s">
		 							<div className="row d-flex align-items-center m-row">

										
										<div className="col-lg-8 m-bottom">
											<div className="fbox-4-txt">
											
												
												<h5 className="h5-sm">Alerts & Notifications</h5>

												
												<p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales 
													a sapien magna at vitae congue tempus
												</p>

											</div>
										</div>

										
										<div className="col-lg-4 m-top">
											<div className="fbox-4-ico ico-100 text-center">
												<span className="flaticon-message"></span>
											</div>
										</div>

									</div>
		 						</div>  
		 					</div>


		 					
		 					<div id="fb-4-4" className="col-md-6">
		 						<div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="1s">
		 							<div className="row d-flex align-items-center m-row">

										
										<div className="col-lg-8 m-bottom">
											<div className="fbox-4-txt">
											
												
												<h5 className="h5-sm">Strong Encryption</h5>

												
												<p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales 
													a sapien magna at vitae congue tempus
												</p>

											</div>
										</div>

										
										<div className="col-lg-4 m-top">
											<div className="fbox-4-ico ico-100 text-center">
												<span className="flaticon-chat-bubble"></span>
											</div>
										</div>

									</div>	
		 						</div>  
		 					</div>	


				 		</div>  
				 	</div>	


				</div>	   
			</section>




			
			<section id="content-3" className="wide-60 content-section division">
				<div className="container">


					
					<div id="cb-1-1" className="cbox-1 pb-25">
						<div className="row d-flex align-items-center">


							
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
									<img className="img-fluid" src="assets/images/img/img-11.png" alt="content-image"/>
								</div>
							</div>


								
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">

								
						 			<span className="section-id grey-color">Unlimited Access</span>

									
									<h2 className="h2-md">Stay connected with friends easily</h2>

								
									<p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia
									   laoreet augue luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat sodales a sapien
									</p>

							
									<p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia
									   laoreet augue luctus magna dolor luctus and egestas sapien egestas vitae nemo volute
									</p>

								</div>
							</div>


						</div>	
					</div>	


					
					<div id="cb-1-2" className="cbox-1 rel">
			 			<div className="row d-flex align-items-center m-row">


							
							<div className="col-md-7 col-lg-6 m-bottom">
								<div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">


									
									<div className="cbox mb-30">
										
										
										<div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-video-player"></span></div> 

									
										<div className="cbox-txt">
											<h5 className="h5-sm">Text, Voice & Video Calls</h5>
											<p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis
											   potenti at suscipit quaerat ultrice tellus viverra
											</p>
										</div>
																																							
									</div>	


									<div className="cbox mb-30">

									
										<div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-smiley"></span></div> 

									
										<div className="cbox-txt">
											<h5 className="h5-sm">Stickers, Emojis, Themes</h5>
											<p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis
												potenti at suscipit quaerat ultrice tellus viverra
											</p>
										</div>
																														
									</div>	


								
									<div className="cbox">
										
									
										<div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-image"></span></div> 

								
										<div className="cbox-txt">
											<h5 className="h5-sm">Share Files & Media</h5>
											<p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis
												potenti at suscipit quaerat ultrice tellus viverra
											</p>
										</div>
																																							
									</div>


								</div>
							</div>	


						
							<div className="col-md-5 col-lg-6 m-top">
								<div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
									<img className="img-fluid" src="assets/images/img/img-05.png" alt="content-image"/>
								</div>
							</div>


						</div> 	


					
						<div className="tra-bkg-wrapper">
							<div className="bg_el_01 wow fadeInRight" data-wow-delay="1s"></div>
							<div className="bg_el_05 wow fadeInRight" data-wow-delay="1s"></div>
						</div>


			 		</div>

				</div>    
			</section>	




		
			<section id="content-5" className="pb-80 content-section">
				<div className="bg-inner bg_purple_img pt-100 division">
					<div className="container white-color">
						<div className="row d-flex align-items-center">


							
							<div className="col-md-5 col-lg-6">
								<div className="content-5-img right-column pc-20 text-center wow fadeInRight" data-wow-delay="0.6s">
									<img className="img-fluid" src="assets/images/img/img-04.png" alt="content-image"/>
								</div>
							</div>

								
							
							<div className="col-md-7 col-lg-6">
								<div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">

										
					 				<span className="section-id">Fastest Messaging</span>

						 			
									<h2 className="h2-md">Unlimited calling, texting and picture messaging</h2>

									
									<p className="p-lg">Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna 
									   vitae auctor congue magna impedit ligula risus. Mauris donec ligula and magnis undo 
									   sapien sagittis sapien pretium enim gravida purus ligula
									</p>

								</div>	
							</div>	


						</div>	  
					</div>	   
				</div>			
			</section>	




			
			<section id="content-1" className="wide-60 mt-inverse-40 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center m-row">


							
						<div className="col-md-7 col-lg-6 m-bottom">
							<div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">

									
					 			<span className="section-id grey-color">Perfect Integration</span>

									
								<h2 className="h2-md">Work smarter with powerful features</h2>

									
								<ul className="simple-list">

									<li className="list-item">
										<p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus
											pretium ligula rutrum tempor sapien
										</p>
									</li>

									<li className="list-item">
										<p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
									</li>

									<li className="list-item">
										<p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien
											undo pretium a purus mauris
										</p>
									</li>

								</ul>

							</div>
						</div>


						
						<div className="col-md-5 col-lg-6 m-top">
							<div className="img-block right-column pc-20 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
								<img className="img-fluid" src="assets/images/img/img-02.png" alt="content-image"/>
							</div>
						</div>


					</div>	  
				</div>	   
			</section>	




			
			<section id="features-5" className="pb-50 features-section division">
				<div className="container">


				
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-70">		

									
								<h2 className="h2-md">Get Ready to Be Surprised</h2>	

								
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


			 		
			 		<div className="fbox-5-wrapper pc-30">
			 			<div className="row">


		 					
		 					<div id="fb-5-1" className="col-md-6">
		 						<div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">

		 							
									<div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-ads"></span></div>

									
									<div className="fbox-txt">
		
										
										<h5 className="h5-sm">Friendly Interface</h5>
											
										
										<p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus
											feugiat at impedit felis undo auctor augue mauris
										</p>

									</div>

		 						</div>
		 					</div>	


		 					
		 					<div id="fb-5-2" className="col-md-6">
		 						<div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">

		 							
									<div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-switch"></span></div>

									
									<div className="fbox-txt">
		
										
										<h5 className="h5-sm">Powerful Options</h5>
											
										
										<p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus
											feugiat at impedit felis undo auctor augue mauris
										</p>

									</div>

		 						</div>
		 					</div>


		 					
		 					<div id="fb-5-3" className="col-md-6">
		 						<div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">

		 							
									<div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-pantone"></span></div>

									
									<div className="fbox-txt">
		
										
										<h5 className="h5-sm">Customization</h5>
											
										
										<p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus
											feugiat at impedit felis undo auctor augue mauris
										</p>

									</div>

		 						</div>
		 					</div>


		 					
		 					<div id="fb-5-4" className="col-md-6">
		 						<div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">

		 							
									<div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-user-1"></span></div>

									
									<div className="fbox-txt">
		
										
										<h5 className="h5-sm">Multiple Accounts</h5>
											
										
										<p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus
											feugiat at impedit felis undo auctor augue mauris
										</p>

									</div>

		 						</div>
		 					</div>


		 					
		 					<div id="fb-5-5" className="col-md-6">
		 						<div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">

		 							
									<div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-browser"></span></div>

									
									<div className="fbox-txt">
		
										
										<h5 className="h5-sm">Online Verification</h5>
											
										
										<p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus
											feugiat at impedit felis undo auctor augue mauris
										</p>

									</div>

		 						</div>
		 					</div>


		 					
		 					<div id="fb-5-6" className="col-md-6">
		 						<div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">

		 							
									<div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-spam"></span></div>

									
									<div className="fbox-txt">
		
										
										<h5 className="h5-sm">Spam Protection</h5>
											
										
										<p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus
											feugiat at impedit felis undo auctor augue mauris
										</p>

									</div>

		 						</div>
		 					</div>


				 		</div>  	
				 	</div>


			 	</div>	   		
			</section>	




			
			<section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
				<div className="container">


						
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-60">		

									
								<h2 className="h2-md">Why Customers Love Us</h2>	

									
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


					
					<div className="reviews-4-wrapper">
						<div className="row">


							
							<div className="col-lg-6">
								<div className="reviews-4-column">


									
									<div className="review-4 wow fadeInUp" data-wow-delay="0.4s">

										
										<div className="review-4-txt">
											<p>Etiam sapien sem at sagittis congue an augue massa varius an egestas suscipit magna 
												a tempus aliquet blandit <span className="txt-highlight">tempor sapien gravida donec</span> ipsum porta	 			   
											</p>
										</div>	

										
										<div className="review-4-data">

											
											<div className="app-rating ico-20 yellow-color">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
											</div>

											
											<h5 className="h5-sm">John Sweet</h5>
											<p>Mac Store Review, USA</p>	

										</div>

									</div>	


									
									<div className="review-4 wow fadeInUp" data-wow-delay="1s">

										
										<div className="review-4-txt">
											<p>An augue cubilia laoreet undo magna a suscipit egestas magna ipsum at ligula vitae
											   <span className="txt-highlight">purus ipsum primis</span> cubilia
											</p>
										</div>

										
										<div className="review-4-data">

											
											<div className="app-rating ico-20 yellow-color">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star-half-empty"></span>
											</div>

										
											<h5 className="h5-sm">Mark Hodges</h5>
											<p>Marketing Manager, Company</p>	

										</div>
										
									</div>	


								</div>
							</div>


				
							<div className="col-lg-6">
								<div className="reviews-4-column">


									
									<div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">

										
										<div className="review-4-txt">
											<p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula
											   augue lectus aenean  ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo tortor tempor sapien gravida donec ipsum porta
											</p>
										</div>	

										
										<div className="review-4-data">

											
											<div className="app-rating ico-20 yellow-color">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
											</div>

											
											<h5 className="h5-sm">Dominic Johnson</h5>
											<p>Chief Strategist, Company</p>	
											
										</div>

									</div>	


								</div>
							</div>


						</div>  
					</div>	


							
			 		<div className="row">
			 			<div className="col-md-12">
			 				<div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">

				 											
								<div className="stores-badge mb-5">

									<a href="#" className="store">
										<img className="appstore-original" src="assets/images/tiendas/appstore.png" alt="appstore-badge" />
									</a>
													
									<a href="#" className="store">
										<img className="googleplay-original" src="assets/images/tiendas/googleplay.png" alt="googleplay-badge" />
									</a> 
								
																					
																		
								 
							
								</div>

								
								<span className="os-version">Available for iOS 8 and Android Devices From 5.5</span>

							</div>
						</div>
					</div>	


				</div>     
			</section>	




		
			<section id="content-11" className="wide-100 content-section division">
				<div className="container">


						
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-60">		

									
								<h2 className="h2-md">Chat with Your Friends Easily</h2>	

									
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


			 	
			 		<div className="row">
						<div className="col-md-12">
							<div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
								<img className="img-fluid" src="assets/images/socials.png" alt="content-image"/>
							</div>
						</div>
					</div>

		
			 		<div className="row">
			 			<div className="col-md-12">
			 				<div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">

			 			
								<a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-rose tra-rose-hover">
								   See NordEx in Action <span className="flaticon-play-button"></span>
								</a> 

							
								<span className="os-version">Available on Android, iOS and macOS</span>

							</div>
						</div>
					</div>


				</div>	   
			</section>




			
			<section id="content-2" className="pb-50 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">


						
						<div className="col-md-5 col-lg-6">
							<div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
								<img className="img-fluid" src="assets/images/img/img-01.png" alt="content-image"/>
							</div>
						</div>


							
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">

									
					 			<span className="section-id grey-color">Register in 30 Seconds</span>

									
								<h2 className="h2-md">Lightning fast and super powerful</h2>

									
								<p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia 
								   laoreet augue luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and 
								   turpis dolores aliquam quaerat sodales a sapien
								</p>

							</div>
						</div>	


					</div>	   
				</div>	   
			</section>	




			
			<section id="download-6" className="bg_selfy wide-100 download-section division">
				<div className="container white-color">
					<div className="row">	


						
						<div className="col-lg-6">	
							<div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">

									
					 			<span className="section-id">Voice & Video Calls</span>

							
								<h2 className="h2-md">Keep Your Favorite People Close to You</h2>	
								
									
								<p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero
									tempus, blandit posuere orci auctor purus euismod an aliquam quaerat purus
								</p>

																			
								<div className="stores-badge">

									
									<a href="#" className="store">
										<img className="appstore" src="assets/images/tiendas/appstore-white.png" alt="appstore-badge" />
									</a>
													
									
									<a href="#" className="store">
										<img className="googleplay" src="assets/images/tiendas/googleplay-white.png" alt="googleplay-badge" />
									</a> 
												
								
																					
								
							
							
								</div>	

							</div>
						</div>	


					</div>    
				</div>	   	
			</section>	




			
			<section id="content-14" className="whitesmoke_shape content-section division">
				<div className="container">


						
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-40">		

									
								<h2 className="h2-md">Accessible for All Platforms</h2>	

									
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


					
					<div className="row">
						<div className="col-md-12">
							<div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
								<img className="img-fluid" src="assets/images/browsers.png" alt="content-image"/>
							</div>
						</div>	
					</div>	


				</div>     
			</section>	




			
			<section id="faqs-1" className="faqs-section division">				
				<div className="container">
					<div className="row">


						
						<div className="col-lg-4">
							<div className="faqs-1-title pc-10">

									
					 			<span className="section-id grey-color">FAQs</span>

								
								<h2 className="h2-md mb-20">Common Questions</h2>

									
								<p className="p-lg">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus,
									blandit and cursus varius
								</p>

							</div>
						</div>


						
						<div className="col-lg-8">
				 			<div id="accordion" role="tablist" className="pc-20">


			 					
			 					<div className="card grey-color">

			 						
								    <div className="card-header" role="tab" id="headingOne">
								      	<h5 className="h5-sm">
								       		<a data-toggle="collapse" href="#collapseOne" role="button" aria-expanded="true" aria-controls="collapseOne">
								         		Can I see NordEx in action before purchasing it?
								        	</a>
								    	 </h5>
								    </div>

								    
								    <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
								     	<div className="card-body">

								     	   		
									       	<p className="p-lg">Maecenas gravida porttitor quis vehicula magna luctus tempor. Quisque 
									       	   vel laoreet turpis. Viverra urna augue, a augue dictum tempor. Sed pulvinar nibh consectetur varius viverra. Laoreet augue ac massa lorem nulla
											</p>	

								     	</div>
								   	</div>


								</div>	


								
								<div className="card grey-color">

									
								    <div className="card-header" role="tab" id="headingTwo">
								      	<h5 className="h5-sm">
								       	    <a className="collapsed" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false" aria-controls="collapseTwo">
								          		I’ve got iPhone 5. Is NordEx compatible with it?
								        	</a>
								     	 </h5>
								    </div>

								    
								    <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
								      	<div className="card-body">

												
								      		<p className="p-lg">Sagittis congue augue egestas volutpat egestas magna suscipit egestas
								      			magna ipsum vitae purus an efficitur ipsum primis in cubilia laoreet augue egestas luctus donec curabitur dapibus libero tempor
											</p>
								        
								     	 </div>
								    </div>

								</div>	


								
								<div className="card grey-color">

								 	
								    <div className="card-header" role="tab" id="headingThree">
								      	<h5 className="h5-sm">
								        	<a className="collapsed" data-toggle="collapse" href="#collapseThree" role="button" aria-expanded="false" aria-controls="collapseThree">
								         		What are the requirements for using NordEx?
								        	</a>
								     	 </h5>
								    </div>

								   	
								    <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
								      	<div className="card-body">

								      			
								      		<p className="p-lg">Sagittis congue augue egestas volutpat egestas magna suscipit egestas 
								      			and magna ipsum vitae purus and efficitur ipsum primis in cubilia laoreet
											</p>
								        
								      			
											<p className="p-lg">Sapien egestas, congue gestas posuere cubilia congue ipsum mauris lectus
											   laoreet gestas neque vitae auctor eros dolor luctus placerat a magna cursus congue magna nihil mpedit ligula sem congue tempor gravida
											</p>

								      	</div>
								    </div>

								</div>	  


								
								<div className="card grey-color">

								 	
								    <div className="card-header" role="tab" id="headingFour">
								    	<h5 className="h5-sm">
								        	<a className="collapsed" data-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseFour">   		
                                        		How does NordEx handle my privacy?
								        	</a>
								     	 </h5>
								    </div>

								    
								    <div id="collapseFour" className="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion">
								      	<div className="card-body">
								        	
								        	
								      		<p className="p-lg">An augue cubilia laoreet and magna suscipit egestas magna ipsum purus
								      		   ipsum primis and augue ultrice ligula egestas suscipit lectus gestas integer congue 
								      		   a lectus porta tristique phasellus neque blandit and tristique
											</p> 

								      	</div>
								    </div>

								</div>	  


								
								<div className="card last-card grey-color">

								 	
								    <div className="card-header" role="tab" id="headingFive">
								    	<h5 className="h5-sm">
								        	<a className="collapsed" data-toggle="collapse" href="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseFive">   		
                                        		How can I cancel my account?
								        	</a>
								     	 </h5>
								    </div>

								    
								    <div id="collapseFive" className="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
								      	<div className="card-body">

								      		
											<p className="p-lg">Curabitur ac dapibus libero. Quisque eu congue tristique neque. Phasellus
											   blandit tristique justo undo aliquam. Aliquam vitae molestie nunc. Quisque sapien justo, aliquet non molestie sed purus, venenatis sem tempor
											</p> 

								      	</div>
								    </div>

								</div>	  

																			
							</div>	


									
							<div className="more-questions pc-20 mt-35">
								<h5 className="h5-sm"><span className="flaticon-check"></span> 
									Have more questions? <a href="contacts.html" className="violet-color">Ask your question here</a>
								</h5>
							</div>


						</div>  

					</div>	
				</div>	   	
			</section>	




			
			<section id="more-apps-1" className="bg_aliceblue wide-70 moreapps-section division">
				<div className="container">


						
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-70">		

									
								<h2 className="h2-md">More Apps by DSAThemes</h2>	

									
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


						
					<div className="abox-1-wrapper">
						<div className="row">


							
							<div className="col-md-6 wow fadeInUp" data-wow-delay="0.4s">
								<a href="#">
									<div className="row abox-1 d-flex align-items-center">

												
										<div className="app-version"><span>2.0.1</span></div>
									
										
										<div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
											<img className="img-fluid" src="assets/images/logo/app-logo-1.png" alt="app-logo"/>
										</div>

											
										<div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	

											<h5 className="h5-xs">FastChat 2</h5>
											<p>Aliquam an augue luctus undo purus</p>

											
											<div className="app-rating ico-20">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star-half-empty mr-5"></span>	
												4.3
											</div>

										</div>
									
									</div>
								</a>
							</div>	


							
							<div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
								<a href="#">
									<div className="row abox-1 d-flex align-items-center">

												
										<div className="app-version"><span>1.3.9</span></div>
									
										
										<div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
											<img className="img-fluid" src="assets/images/logo/app-logo-2.png" alt="app-logo"/>
										</div>

											
										<div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	

											<h5 className="h5-xs">MuMemos</h5>
											<p>Aliquam an augue luctus undo purus</p>

											
											<div className="app-rating ico-20">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star mr-5"></span>	
												5.0
											</div>

										</div>
									
									</div>
								</a>
							</div>	


							
							<div className="col-md-6 wow fadeInUp" data-wow-delay="0.8s">
								<a href="#">
									<div className="row abox-1 d-flex align-items-center">

												
										<div className="app-version"><span>4.1.6</span></div>
									
										
										<div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
											<img className="img-fluid" src="assets/images/logo/app-logo-4.png" alt="app-logo"/>
										</div>

											
										<div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	

											<h5 className="h5-xs">Music Player</h5>
											<p>Aliquam an augue luctus undo purus</p>

											
											<div className="app-rating ico-20">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star-half-empty mr-5"></span>	
												4.65
											</div>

										</div>
									
									</div>
								</a>
							</div>	


							
							<div className="col-md-6 wow fadeInUp" data-wow-delay="1s">
								<a href="#">
									<div className="row abox-1 d-flex align-items-center">

												
										<div className="app-version"><span>14.1.0</span></div>
									
									
										<div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
											<img className="img-fluid" src="assets/images/logo/app-logo-3.png" alt="app-logo"/>
										</div>

											
										<div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	

											<h5 className="h5-xs">PowerSaver</h5>
											<p>Aliquam an augue luctus undo purus</p>

											
											<div className="app-rating ico-20">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star-half-empty mr-5"></span>	
												4.21
											</div>

										</div>
									
									</div>
								</a>
							</div>	


							
							<div className="col-md-6 wow fadeInUp" data-wow-delay="1.2s">
								<a href="#">
									<div className="row abox-1 d-flex align-items-center">

												
										<div className="app-version"><span>2.2.1</span></div>
									
										
										<div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
											<img className="img-fluid" src="assets/images/logo/app-logo-5.png" alt="app-logo"/>
										</div>

											
										<div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	

											<h5 className="h5-xs">StocksHub</h5>
											<p>Aliquam an augue luctus undo purus</p>

											
											<div className="app-rating ico-20">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star-half-empty mr-5"></span>	
												4.45
											</div>

										</div>
									
									</div>
								</a>
							</div>	


						
							<div className="col-md-6 wow fadeInUp" data-wow-delay="1.4s">
								<a href="#">
									<div className="row abox-1 d-flex align-items-center">

												
										<div className="app-version"><span>2.3.5</span></div>
									
										
										<div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
											<img className="img-fluid" src="assets/images/logo/app-logo-6.png" alt="app-logo"/>
										</div>

											
										<div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	

											<h5 className="h5-xs">Equalizer 2</h5>
											<p>Aliquam an augue luctus undo purus</p>

											
											<div className="app-rating ico-20">
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star"></span>
												<span className="flaticon-star mr-5"></span>
												4.0
											</div>

										</div>
									
									</div>
								</a>
							</div>

						
						</div>    
					</div>	


				</div>	   
			</section>	




			
			<section id="blog-1" className="wide-60 blog-section division">				
				<div className="container">


						
					<div className="row">	
						<div className="col-lg-10 offset-lg-1">
							<div className="section-title text-center mb-70">		

									
								<h2 className="h2-md">Our Tips and Latest News</h2>	

									
								<p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
							       tempus, blandit and cursus varius magna tempus a dolor
								</p>
									
							</div>	
						</div>
					</div>


			 
				 	<div className="row">


				 	
				 		<div id="b-post-1" className="col-md-6 col-lg-4">
				 			<div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
			 			
					 			
					 			<div className="blog-post-img">
									<img className="img-fluid" src="assets/images/blog/post-1-img.jpg" alt="blog-post-image" />
								</div>

								
								<div className="blog-post-txt">

									
									<p className="p-md post-tag">NordEx News</p>	

									
									<h5 className="h5-sm">
										<a href="single-post.html">Tempor sapien donec gravida ipsum a porta justo vitae</a>
									</h5>

									
									<p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna
										dolor vitae auctor congue 
									</p>

									
									<div className="post-meta">
										<div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar"/></div>
										<p>12 min read</p>
									</div>	

								</div>

							</div>
				 		</div>	


						
				 		<div id="b-post-2" className="col-md-6 col-lg-4">
				 			<div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
			 			
					 			
					 			<div className="blog-post-img">
									<img className="img-fluid" src="assets/images/blog/post-2-img.jpg" alt="blog-post-image" />
								</div>

								
								<div className="blog-post-txt">

									
									<p className="p-md post-tag">Inspiration</p>	

									
									<h5 className="h5-sm">
										<a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and 
										   dolor libero risus
										</a>
									</h5>

									
									<p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna
										dolor vitae auctor congue 
									</p>

									
									<div className="post-meta">
										<div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar"/></div>
										<p>8 min read</p>
									</div>	

								</div>	

							</div>
				 		</div>	


				 		
				 		<div id="b-post-3" className="col-md-6 col-lg-4">
				 			<div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
			 			
					 			
					 			<div className="blog-post-img">
									<img className="img-fluid" src="assets/images/blog/post-3-img.jpg" alt="blog-post-image" />
								</div>

								
								<div className="blog-post-txt">

									
									<p className="p-md post-tag">Tutorials</p>	

									
									<h5 className="h5-sm">
										<a href="single-post.html">Tempor sapien NordEx, donec gravida ipsum a porta justo</a>
									</h5>

									
									<p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna
										dolor vitae auctor congue 
									</p>

									
									<div className="post-meta">
										<div className="post-author-avatar"><img src="assets/images/post/post-author-1.jpg" alt="author-avatar"/></div>
										<p>22 min read</p>
									</div>	

								</div>	

							</div>
				 		</div>	

					</div>	

				</div>	   		
			</section>	


			<section id="newsletter-1" className="pb-20 newsletter-section division">
				<div className="container">
					<div className="newsletter-wrapper bg_white">
						<div className="row d-flex align-items-center">


								
							<div className="col-lg-6">
								<div className="newsletter-txt">	

										
						 			<span className="section-id">Subscribe to Our Newsletter</span>

						 				
									<h4 className="h4-xl">Stay up to date with our news, ideas and updates</h4>	

								</div>								
							</div>


							
							<div className="col-lg-6">
								<form className="newsletter-form">
											
									<div className="input-group">
										<input type="email" className="form-control" placeholder="Your email address" required id="s-email"/>								
										<span className="input-group-btn">
											<button type="submit" className="btn btn-md btn-rose tra-rose-hover">Subscribe Now</button>
										</span>										
									</div>

										
									<label className="form-notification"></label>
												
								</form>							
							</div>	 


						</div>	  
					</div>    
				</div>	   	
			</section>		

		</div>	



            </div>

        )
    }
}
export default Home 