import VideoPopup from 'components/VideoPopup';
import React from 'react';

class StepInstall extends React.Component {
    render() {
        return (
            <>
                <div className="bg_selfy">
                    <section id="process-2" className=" wide-100 process-section division">
                        <div className="container white-color">
                            <div className="post-inner-img">
                                <VideoPopup videoId={'F4SNyIiwM6k'} />
                                <br />
                                <br />
                            </div>
                            {/* SECTION TITLE */}
                            <div className="row">
                                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                    <div className="section-title text-center mb-60">
                                        <h3 className="h3-sm">¡Empecemos a estar más cerca!</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <ul className="process-skyblue processbar pc-35">
                                    {/* PROCESS BOX #1 */}
                                    <li id="step-2-1" className="col-md-4">
                                        <div className="pbox-2 pc-20 text-center">
                                            <h5 className="h5-sm">Descarga la app</h5>
                                            <p className="p-lg">Disponible en las tiendas de Ios y Android</p>
                                        </div>
                                    </li>
                                    {/* PROCESS BOX #2 */}
                                    <li id="step-2-2" className="col-md-4">
                                        <div className="pbox-2 pc-20 text-center">
                                            <h5 className="h5-sm">Regístrate </h5>
                                            <p className="p-lg">Si cuentas con una cuenta de ahorros activa en alguna de las instituciones aliadas, sigue el proceso de registro para activar tu usuario!</p>
                                        </div>
                                    </li>
                                    {/* PROCESS BOX #3 */}
                                    <li id="step-2-3" className="col-md-4">
                                        <div className="pbox-2 pc-20 text-center">
                                            <h5 className="h5-sm">Usa la app y transacciona sin costo</h5>
                                            <p className="p-lg">Genera cobros, pagos o mantenmiento de cuentas registradas en cualquier momento!</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>	  {/* End row */}
                        </div>	   {/* End container */}

                    </section>	{/* END PROCESS-2 */}
                </div>
            </>
        )
    }
}
export default StepInstall 